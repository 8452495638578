@import '../../../assets/scss/styles.scss';

.calendarNavbar {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	box-sizing: border-box;
	padding: 0 30px;
	height: 60px;
	margin-top: 10px;

	@include rwd(450){
		height: 90px;
		margin-top: 0px;
	}

	@include rwd(800) {
		justify-content: space-between;
		
	}

	> .title {
		> h1 {
			color: $grey1;
		}
	}

	> .navigation {
		display: flex;
		align-items: center;

		@include rwd(800) {
			position: absolute;
			left: 50%;
			transform: translateX(-86%);
		}

		@include xl {
			transform: translateX(-50%);
		}

		> button {
			svg {
				stroke: $grey1;
				width: 26px;
				stroke-width: 2;
			}
		}

		> .dateSelectShow {
			margin-left: 7px;
			margin-right: 7px;
			width: 170px;
			text-align: center;

			@include rwd(800) {
				width: 200px;
			}

			> .dateSelectMonth {
				text-transform: capitalize;
				color: $grey1;
			}

			> .dateSelectWeek {
				text-align: center;
				padding-bottom: 14px;
				> h2 {
					color: $grey2;
				}

				> h1 {
					color: $grey1;
					margin-top: 3px;
				}
			}
		}
	}

	> .options {
		display: flex;
		align-items: center;

		> .btn {
			border-radius: 5px !important;
		}

		> .calendarViewSwitch {
			display: flex;
			align-items: center;
			margin-left: 20px;

			> .btn {
				border-radius: 5px !important;

				&:first-child {
					border-top-right-radius: 0 !important;
					border-bottom-right-radius: 0 !important;
				}

				&:nth-child(2) {
					border-top-left-radius: 0 !important;
					border-bottom-left-radius: 0 !important;
				}
			}
		}
	}
}

@import '../../../../assets/scss/styles.scss';

.programsCreated {
	.programsList {
		margin-top: 15px;
		@include qhd {
			margin-top: 25px;
		}
		.programLineProgramPage {
			margin-top: 10px;
			@include qhd {
				margin-top: 14px;
			}

			&:first-child {
				margin-top: unset;
			}
		}
	}

	.programsDataEmpty {
		p {
			color: $grey2;
			line-height: 20px;
			margin-top: 10px;
		}

		.btn {
			margin-top: 15px;
		}
	}
}

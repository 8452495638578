@import '../../assets/scss/styles.scss';

#timerPage{
	background:orange;

	height:calc(100vh - 46px);

	@include rwd(800){
		height:calc(100vh - 54px);
	}
}
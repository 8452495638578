@import '../../assets/scss/styles.scss';

.userCard{
	display: flex;
	align-items: center;

	.infos{
		flex:1;
		margin-left:14px;
		padding-bottom:3px;
		h1{
			color:$grey1;
			line-height:16px;
		}
		h2{
			color:$grey2;
			margin-top:4px;
		}
	}
}
@import '../../../../assets/scss/styles';


.programHomePageNavbar{

	.mobileMenu{
		//position: absolute;
		// top:38px;
		// right:28px;
		//right:0;

		// @include rwd(860){
		// 	top:50px;
		// }
        padding-left: 40px;

		.selected{
			display: flex;
			align-items: center;
			justify-content: flex-end;
			color:$grey2;
			text-align: right;
			cursor:pointer;

			svg{
				margin-left:10px;
				width:15px;
				stroke: $grey2;
				stroke-width:2;
				fill:none;
				padding-top:1px;
			}
		}

		.menuContainer{
			background:white;
			box-shadow: 0px 0px 10px 4px rgba(0,0,0,0.03);
			padding:12px 20px;
			margin-top:15px;
			display: flex;
			flex-direction: column;
			position:absolute;
			right:0px;
			z-index: 2000;
			border-radius:10px;

			transform: translateY(-20px);
			opacity:0;

			a{
				box-sizing: border-box;
				padding:15px;
				color:$grey1;
			}

			.logoutLink{
				// display: inline-block;
				box-sizing: border-box;
				padding:15px;
				width:200px;
				display: flex;
				align-items: center;
				color:$error !important;

				svg{
					width:12px;
					stroke:$error;
					stroke-width:3;
					margin-left:10px;
				}
			}
		}

	}
}

@import '../../../assets/scss/styles.scss';

.calendarSessionPage{

	max-width: 1360px;
	margin-left: auto;
	margin-right: auto;
	padding:10px 0 40px 0;

	display: flex;
	flex-direction: column;

	// background:blue;
	min-height: calc(100vh - 46px);

	box-sizing: border-box;

	@include rwd(800){
		padding:30px 0 40px 0;
		min-height: calc(100vh - 54px);
	}

	@include qhd{
		max-width: 1560px;
	}
	
	>.row1{

		@include rwd(800){
			display: flex;
			gap:20px;
			flex:1;
		}

		@include rwd(1600){gap:40px;}
		@include qhd{gap:60px;}
		>.column{
			@include rwd(800){
				flex:1;
			}

		}

		>.column1{
			// background:red;

			@include rwd(800){
				display: flex;
				flex-direction: column;
				flex:1;
				width:50%;

			}
		}

		>.column2{
			// background:orange;
			@include rwd(800){
				flex:1;
			}
		}
	}

}
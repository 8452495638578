@import '../../assets/scss/styles.scss';

.buttonBig{
	background: $grey4;
	padding:16px;
	border-radius: 20px;
	display: flex;
	align-items: center;
	box-sizing: border-box;

	transition: background 100ms ease-in-out;

	&:hover{
		background: $grey3;
	}

	.icon{
		height:28px;
		width:28px;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 10px;
		box-shadow: 0px 5px 13px rgba(0, 0, 0, 0.1);
		svg{
			stroke-linecap: round;
			stroke-linejoin: round;
			fill:none;
			width:12px;
			stroke-width: 3;
			stroke: white;
		}
	}


	h1{
		color:$grey1;
		margin-left:12px;
	}
}
@import '../../../../../assets/scss/styles.scss';

.timerFormEmom{
	>.emomOne{
		display: flex;
		gap:40px;

		>.inputEvery{
			flex:5;
		}

		>.inputRounds{
			flex:2;
		}
	}

	>div >.timerFormStep{
		margin-top: 30px;

		>.stepWrapperEveryRound{
			display: flex;
			gap:40px;
			margin-top: 15px;

			>.everyRounds{
				flex:1;
			}
		}
	}
}
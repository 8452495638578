@import '../../../../../assets/scss/styles.scss';

.commentInput{
					
	> .inputAreaChat {
		border:1px solid $grey3;
		border-radius: 15px;
		> .inputRow {
			padding:7px 16px;

		}

	}

}
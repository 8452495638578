@import '../../assets/scss/styles.scss';

.registerData{
	width:100%;
	.formContainer{
		.formValidationClassic{
			width:100%;
			// background:red;
			.btn{
				width:100% !important;
				box-sizing: border-box;
			}

		}
	}

}
@import '../../assets/scss/styles.scss';

.wodbookPage {
	box-sizing: border-box;
	padding: 38px 26px;

	padding-bottom: 60px;
	color: $grey1;

	@include rwd(800) {
		padding: 48px 46px;
	}

	> h1 {
		display: flex;
		justify-content: space-between;
		align-items: center;

		@include rwd(800) {
			width: 49%;
			max-width: 500px;
		}

		> button {
			> svg {
				width: 16px;
				stroke-width: 3;
			}
		}


		
	}

	> .content {
		@include rwd(800) {
			display: flex;
			gap: 20px;
		}
		> .column {
			@include rwd(800) {
				width: 50%;
			}
		}

		> .column1 {
			max-width: 500px;
			margin-left: auto;
			margin-right: auto;
			@include rwd(800) {
				margin-left: unset;
				margin-right: unset;
			}
		}

		> .column2 {
			@include rwd(1400) {
				flex: 1;
				width: unset;
			}
		}
	}
}

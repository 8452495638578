@import '../../../../assets/scss/styles.scss';

.settingsAbout{
	.settingsAboutWrapper{
		img{
			width:100px;
		}

		p{
			color:$grey1;
			margin-top:20px;
			line-height: 22px;
			// max-width: 380px;

			@include qhd{
				line-height: 24px;
				// max-width: 430px;
			}
		}
	}
}
@import '../../../../../assets/scss/styles.scss';

.timerFormAhap{

	>div >.timerFormStep{
		margin-top: 30px;

		>.stepLine{
			display: flex;
			gap:20px;

			>.stepRoundTime{
				flex:1;
			}

			>.stepRestTime{
				flex:1;
			}
		}
	}

}
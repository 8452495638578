@import '../../../../assets/scss/styles.scss';

.calendar {
	padding: 0px 30px 40px 30px;
	box-sizing: border-box;
	transition: left 200ms ease-out;
	left: 0;

	@include rwd(1600) {
		padding: 0px 30px 40px 0px;
		position: absolute;
		left: 270px;
		right: 0;
	}

	> .table {
		width: 100%;
		box-sizing: border-box;
		border-collapse: collapse;
		border-spacing: 0;
		table-layout: fixed;

		> thead {
			th,
			td {
				border: 3px solid $grey4;
			}
			> .titles {
				padding: 0;

				.titleColumn {
					background: $grey4;
					height: 46px;
					text-align: center;
					color: $grey1;
					box-sizing: border-box;
				}

				.titleColumnWeek {
					text-align: left;
					padding: 15px 10px;

					> h2 {
						color: $grey2;
						margin-top: 6px;
					}
				}
			}
		}

		> tbody {
			width: calc(100% - 12px);
			> tr > td {
				border: 3px solid $grey4;
			}
		}

		> .tbodyWeek {
			> tr > td {
				border: 2px solid $grey4;
			}
		}
	}
}

.calendarViewFull {
	@include rwd(1600) {
		left:  + 30px;
	}
}

// .calendarViewFullProgram {
// 	@include rwd(1600) {
// 		left: 30px;
// 	}
// }
@import '../../../../assets/scss/styles.scss';

.recordCategorySelector{
	position:relative;
	height:43px;
	cursor:pointer;

	.recordCategorySelectorWrapper{
		position:absolute;
		box-sizing: border-box;
		left:0;
		top:0;
		width:100%;
		background:white;

		border-radius: 10px;
		border: 1px solid #E6EAF4;
		

		.defaultSelect{
			border-radius: 10px;
			
			h1{color:$grey2;}

			svg{
				width:10px;
				fill:$grey2;
				position:absolute;
				right:15px;
				top:18px;
			}
		}

		.option{
			display: flex;
			justify-content: space-between;
			box-sizing: border-box;
			padding:14px 15px;

			&:hover{background:$grey4;}
		}

	}
}
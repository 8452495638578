@import '../../../../../assets/scss/styles.scss';

.timerFormForTime{

	>.timerFormStep{
		margin-top: 30px;

		>.stepWrapper{
			// background:pink;
			display: flex;
			gap:20px;

			>.restTime{
				flex:1;
			}
		}
	}

}
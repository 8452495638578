@import '../../../assets/scss/styles.scss';

#navbar {
	display: flex;
	position:fixed;
	box-sizing: border-box;
	height: 46px;
	top:0;
	right:0;
	left:0;
	padding: 0 24px;
	justify-content: space-between;
	align-items: center;
	background: $grey4;
	z-index:10000;

	@include rwd(800){
		height: 54px;
		padding: 0 28px;
	}

	@include xl{left:260px;}

	@include xxl{
		left:300px;
		padding: 0 34px;
	}

	svg {
		stroke: $grey1;
		stroke-linecap: round;
		stroke-linejoin: round;
		margin: 0;
		cursor:pointer;
	}

	.left {
		padding: unset;
		display: flex;
		align-items: center;

		.slidebarButton {
			width: 18px;
			padding: unset;
		}

		svg{
			@include xl{display:none;}
		}

		.breadcrumb{
			margin-left:31px;
			a{
				color:$grey2;
			}
			
		}
	}

	.right {
		display: flex;
		align-items: center;
	}
}

@import '../../../../assets/scss/styles.scss';

.wodScores{
	max-width: 500px;
	color:$grey1;
	>.addScore{
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	>.scoresList{
		margin-top: 20px;

		// >.scoreListWrapper{
		// 	display:flex;
		// 	flex-direction: column;
		// 	align-items: center;
			
		
		// 	>.scoreWrapper{
		// 		background:$grey4;
		// 		border-radius: 10px;
		// 		box-sizing: border-box;
		// 		padding:20px;
		// 		margin-top: 15px;
		// 		max-width: 450px;
		// 		flex:1;

		// 		>.scoresListData{
		// 			margin-top: 15px;

				

		// 				>.scoreData{
		// 					max-width: 450px;
		// 					flex:1;
		// 					display: flex;
		// 					align-items: center;
		// 					justify-content: space-between;
		// 					margin-top: 10px;
		// 					>.left{
		// 						>.score{
		// 							background:$primary;
		// 							border-radius: 7px;
		// 							color:white;
		// 							padding:7px 10px;
		// 						}
		// 					}

		// 					>.right{
		// 						>.name{

		// 						}
		// 					}
		// 				}



					
		// 		}
		// 	}


			
		// 	>.actions{
		// 		margin-left: 18px;
		// 		>button{
		// 			>svg{
		// 				width:18px;
		// 				stroke-width:2;
		// 			}
		// 		}
		// 	}

		// }

	}
}
@import '../../../../assets/scss/styles.scss';

.welcomeWrapper {
	width: 100%;

	h1 {
		color: $grey1;
		line-height: 28px;
	}

	.welcomeWidgetWrapper {

		@include xxl{
			display: flex;
			flex-direction: row;
			align-items: flex-start;
		}

	}

	//Welcome Message
	.welcomeMessage {
		width: 100%;
		margin-top: 22px;

		@include xxl{
			width:calc(64% - 20px);
			margin-right:20px;
		}

		@include rwd(1700){
			width:calc(65% - 20px);
		}

		@include qhd{
			width:calc(65% - 20px);
		}

		.welcomeMessageWidgetWrapper {
			background-repeat: no-repeat;
			background-size: auto 150%;
			background-position: right center;

			padding: 30px 27px;

			h1 {
				color: white;
			}
			p {
				color: white;
				line-height: 20px;
				margin-top: 12px;

				@include rwd(1700){
					max-width: 280px;
				}

				@include qhd{
					max-width: 350px;
					line-height: 24px;
				}

			}

			.btn {
				margin-top: 24px;
			}
		}

		.backgroundGreen {
			background-color: $primary;
			background-image: url(../../../../assets/img/background/bolt_greenlight_small.png);
		}
		.backgroundBlue {
			background-color: $secondary;
			background-image: url(../../../../assets/img/background/bolt_bluelight_small.png);
		}
	}

	//Welcome Actions
	.welcomeActions {
		margin-top: 20px;
		overflow: unset;

		@include xxl{
			width:36%;

		}

		@include rwd(1700){
			width:35%;
		}

		.welcomeActionsDataEmpty {
			background:$grey4;
			overflow: unset;

			.welcomeActionsWrapper {

				h1 {
				}

				p {
					color: $grey2;
					line-height: 20px;
					margin-top: 12px;
				}

				button {
					margin-top: 18px;

					@include xxl{
						width:120%;
						max-width: unset;
						transform:translateX(0%);
					}

					@include rwd(1700){
						// width:100%;
					}

					@include qhd{
						// width:100%;
						// transform:translateX(30px);
					}
				}
			}


		}

		.welcomeActionsButtons{
			display:flex;
			flex-direction: column;
			align-items: center;

			@include rwd(500){
				flex-direction: row;
				justify-content: center;
			}

			@include xxl{
				flex-direction: column;
				align-items: flex-start;
			}

			.buttonBig{
				width: 193px;

				&:nth-child(2){
					margin-top:10px;
					@include rwd(500){
						margin-top:unset;
						margin-left:20px;
					}

					@include xxl{
						margin-top:10px;
						margin-left:unset;
					}

				}
			}




			>.coach {
				background: $grey4;
				box-sizing: border-box;
				padding: 16px 24px;
				border-radius: 20px;
				width: 100%;

				display: flex;
				flex-direction: column;
				align-items: center;

				@include rwd(450){
					max-width: 360px;
				}

				@include rwd(1400){max-width: unset;}
				
				.coachInfosWrapper{
					display: flex;
					flex-direction: column;
					align-items: center;

					@include rwd(450){
						flex-direction: row;
						gap:20px;
					}

					@include rwd(1400){
						flex-direction: column;
						gap:unset;
					}

					>.infosCoach{
						text-align: center;
						margin-top: 16px;
	
						>h2{color:$grey1;}

						@include rwd(450){
							margin-top: unset;
						}
	
						@include rwd(1400){
							margin-top: 16px;
							>h1{font-size:15px;}
						}
					}
				}



				>.buttonBig{
					margin-top: 10px;
					margin-left: unset !important;
					margin-right: unset !important;

					width:unset;
					>h1{font-size: 14px;}

					@include rwd(450){
						margin-top: 0px;
					}

					@include rwd(1400){
						margin-top: 10px;
						>h1{font-size: 12px;}
					}

					@include rwd(1700){
						>h1{font-size: 14px;}
					}
				}

			}


		}
	}
}

@import '../../../assets/scss/styles.scss';

.searchListUser{
	.userCard{
		margin-top:20px;
		cursor: pointer;
		transition: transform 120ms ease-in-out;
		
		&:hover{transform: scale(1.05)}
		&:first-child{margin-top:0;}
	}
}

 	

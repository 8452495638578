@import '../../../../assets/scss/styles.scss';

#programHomePage {
	.programSessions {
		overflow: unset;
		padding: 20px 0;

		.todaySessions {
			margin-top: 30px;
			@include qhd {
				margin-top: 40px;
			}
		}

		.futurSessions {
			margin-top: 30px;
			@include qhd {
				margin-top: 40px;
			}
			.decoLine {
				width: 120px;
				height: 1px;
				background: $grey4;
				margin-left: 50px;
			}
			> h1 {
				color: $grey1;
				margin-top: 15px;
				@include qhd {
					margin-top: 20px;
				}
			}
			> h2 {
				margin-top: 15px;
			}
		}

		.dateBlock {
			margin-top: 20px;
		}

		.sessionListeDate {
			color: $grey2;
		}

		.sessionsList {
			@include qhd {
				margin-top: 20px;
			}
			.sessionLineProgramHome {
				padding: 12px 20px;
				background: $grey4;
				display: flex;
				align-items: center;
				justify-content: space-between;
				box-sizing: border-box;
				margin-top: 6px;
				border-radius: 10px;
				cursor: pointer;

				transition: transform 120ms ease-in-out;

				&:hover {
					transform: scale(1.05);
				}

				@include qhd {
					margin-top: 10px;
					padding: 15px 20px;
				}

				.sessionLineLeft {
					display: flex;
					flex-direction: column;
					justify-content: flex-start;
					h1 {
						color: $grey1;
					}
					h2 {
						color: $grey2;
						margin-top: 6px;
						margin-left: unset;
					}
				}

				.sessionLineRight {
					svg {
						width: 22px;
						stroke: $grey1;
						stroke-width: 2;
					}
				}
			}
		}
	}
}

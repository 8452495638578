@import '../../../../assets/scss/styles.scss';

.sessionLine{
	display: flex;
	justify-content: space-between;
	align-items: center;
	background:$grey4;
	border-radius: 10px;
	box-sizing: border-box;
	padding: 12px 14px;
	cursor:pointer;

	transition: transform 120ms ease-in-out;

	&:hover{
		transform:scale(1.05);
	}

	.left{
		display: flex;
		align-items: center;

		.image{
			border-radius:15px;
			width: 44px;
			height:44px;
			overflow: hidden;
			img{
				width:100%;
				height:100%;
				object-fit: cover;
			}
		}

		.texts{
			margin-left:18px;
			h1{
				color:$grey1;
			}
			h2{
				color:$grey1;
				margin-top:6px;
			}
		}
	}

	.right{
		color:$grey2;
	}
}
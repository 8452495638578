@import '../../../../../assets/scss/styles.scss';

.timeChronoAppPauseModal{
	position:fixed;
	top:0;
	left:0;
	bottom:0;
	right:0;
	background:rgba(0,0,0,0.87);
	z-index: 10000000000000;

	display: flex;
	align-items: center;
	justify-content: center;
	transition: opacity 200ms ease-out;

	>.modalContent{
		position:relative;
		// background:orange;
		display: flex;
		width:100%;
		height:100%;
		flex-direction: column;
		justify-content: flex-end;

		>.text{
			position:absolute;
			top:50%;
			transform: translateY(calc(-50% - 35px));
			width: 100%;
			text-align: center;
			color:white;
			font-size:20vw;
			display: block;
			// background:red;

			@include md{
				font-size:20vw;
				transform: translateY('60%');
			}

			@include xl{
				font-size: 12vw;
			}
		}

		>.actions{
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding:20px 20px;
			width:100%;
			max-width: 800px;
			margin-left: auto;
			margin-right: auto;
			box-sizing: border-box;


			>.btnTimerModal{
				width:40%;
				padding: 12px 0;
				background:#294872;
				color:white;
				font-size:14px;
				letter-spacing: 0.5px;
				border-radius: 10px;
				cursor:pointer;
				transition: all 170ms ease-out;
				max-width: 240px;

				@include md{
					font-size:17px;
					padding: 14px 0;
				}

				&:hover{
					background:#213966;
				}
			}

			>.red{
				background:$error;
				&:hover{
					background:$errorHover;
				}
			}
		}
	}
}

.timeChronoAppPauseModalOut{
	opacity:0;
}
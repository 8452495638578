@import '../../../../../assets/scss/styles.scss';

.comment {
	color: $grey1;
	width: 100%;

	> .replies {
		margin-left: 41px;
		margin-top: 24px;
		border-left: 1px solid $grey3;

		> .moreAnswers {
			padding-left: 15px;
			margin-top: 20px;
		}

		> .commentTemplate {
			margin-top: 24px;
			&:first-child {
				margin-top: 0;
			}
		}
	}

	.commentTemplate {
		display: flex;

		> .left {
			padding: 0 8px;
			// background:red;

			> .userIconWrapper {
				width: 25px;
			}
		}

		> .right {
			flex: 1;
			// background:green;

			> .infos {
				display: flex;
				justify-content: space-between;
				align-items: flex-start;
				padding-top: 4px;
				// background:blue;

				> .left {
					display: flex;

					> h1 {
					}

					> h2 {
						margin-left: 4px;
						color: $grey2;
					}
				}

				> .right {
					> .likes {
						color: $secondary;
						display: flex;
						> svg {
							width: 10px;
							stroke-width: 3;
							stroke: $secondary;
							margin-left: 5px;
						}
					}
				}
			}

			> .content {
				background: $grey4;
				padding: 12px 14px;
				border-radius: 10px;
				margin-top: 6px;
				// background:red;

				> .contentImage {
					width: 100%;
					max-height: 35vw;
					overflow: hidden;
					border-radius: 20px;
					display: flex;
					align-items: center;
					justify-content: center;
					margin-left: auto;
					margin-right: auto;

					@include rwd(600) {
						max-width: 360px;
						max-height: 220px;
					}

					> img {
						width: 100%;
						height: 100%;
						object-fit: cover;
						cursor: pointer;
					}

					.imgModal {
						max-width: 80vw;
						max-height: 80vh;
						img {
							max-width: 80vw;
							max-height: 80vh;
							display: block;
							// width:100px;
						}
					}
				}

				> .contentText {
					overflow-wrap: break-word;
					-webkit-hyphens: auto;
					-ms-hyphens: auto;
					hyphens: auto;
					max-width: 70vw;
					line-height: 16px;

					@include rwd(800) {
						max-width: 30vw;
					}
					@include rwd(1700) {
						max-width: 550px;
					}

					@include rwd(2200) {
						max-width: 650px;
						line-height: 19px;
					}
				}
			}

			> .actions {
				margin-top: 14px;
				display: flex;
				// background:red;

				> button {
					display: flex;
					align-items: center;
					color: $grey2;

					> svg {
						width: 10px;
						stroke-width: 3;
						stroke: $grey2;
						margin-right: 4px;
					}

					&:nth-child(2) {
						margin-left: 14px;
					}

					&:hover {
						color: $grey1;
						> svg {
							stroke: $grey1;
						}
					}
				}

				.liked{
					// background:red;
					>svg{
						fill:$grey2;
					}
				}
			}

			> .commentInput {
				
				> .inputAreaChat {
					margin-top: 20px;
					> .inputRow {
						// 
						padding: 7px 16px;
					}
					// background:red;
				}
				
			}
		}
	}
}

@import '../../../../assets/scss/styles.scss';

.moveSessionModal{
	padding: 28px 38px;
	width:100%;
	max-width: 400px;
	box-sizing: border-box;
	>.textValidation{
		color:$grey1;
		line-height: 24px;

	}

	>.actions{
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-top: 20px;
	}
}


@import '../../../../assets/scss/styles.scss';

#settingsPage{

	.DesktopSidebar{
		//background:red;

		box-sizing: border-box;
		padding:20px;
		margin-right:70px;
        min-width: 240px;
        max-width: 240px;

		.selected{
			color:$grey1;
		}

		.menuContainer{
			margin-top:50px;
		}

		.separator{
			width:100%;
			height:1px;
			background:$grey3;
			margin-top:30px;
			margin-bottom:30px;
		}

		.disconnect{
			color:$error;
			transition: color 120ms ease-in-out;
			padding-left:25px;

			&:hover{
				color:$errorHover;
			}
		}
	}

}

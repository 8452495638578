@import '../../../assets/scss/styles.scss';

#programCalendarPage{
	box-sizing: border-box;
	padding: 0px 0px;
	position:relative;
	min-height: calc(100vh - 46px);

	@include rwd(800) {
		min-height: calc(100vh - 54px);
	}

	>.programNavbarContainer{
		padding: 28px 26px 0px 26px;
		box-sizing: border-box !important;

		@include rwd(800){
			padding: 40px 26px 0px 26px;
		}
		// background:red;
	}

	@include rwd(860){
		// padding-top:40px;
		padding-bottom:60px;
	}



}



@import '../../../assets/scss/styles.scss';

.searchListProg{
  .progCard{
    margin-top:20px;
    cursor: pointer;
    transition: transform 120ms ease-in-out;

    &:hover{transform: scale(1.05)}
    &:first-child{margin-top:0;}

    @include qhd{
      .logo{
        width:40px !important;
        height:40px !important;
 
      }
    }
  }
}

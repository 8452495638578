@import '../../../../assets/scss/styles.scss';

#settingsPage{

	.settingsSupport{

		>.settingsSupportWrapper{
			>h1{
				color:$grey1;
			}
			>p{
				color:$grey2;
				margin-top:10px;
				// max-width: 380px;
				line-height: 22px;
				margin-top:15px;

				@include qhd{
					// max-width: 410px;
					line-height: 24px;
				}
			}

			.contactInfo{
				border-top:1px solid $grey2;
				width:100%;
				max-width: 300px;
				padding-top:25px;
				margin-top:20px;

				>.info{
					color:$grey1;
					display: flex;
					align-items: center;
					margin-top:17px;

					&:nth-child(1){
						margin-top:0;
					}

					svg{
						width:15px;
						stroke:$grey1;
						stroke-width: 2;
						margin-right:12px;
					}
				}
			}
		}

	}
	
}
@import '../../assets/scss/styles.scss';

#loginPage{

	min-height:740px;


	.rightLoginWrapper{
		background-image:url('../../assets/img/login-cover-opti.jpg');
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center center;
	}
}
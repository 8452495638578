@import '../../../assets/scss/styles.scss';

.calendarApp {
	display: flex;
	flex-direction: column;
	> .row2 {
		flex: 1;
		@include rwd(1600) {
			> .column1 {
				position: relative;
			}

			> .column2 {
				position:relative;
			}
		}
	}

	>.btnAddSession{
		position:fixed;
		bottom:20px;
		left:50%;
		transform:translateX(-50%);
		width:87vw;
		box-sizing: border-box;
		padding:12px 10px;
		max-width: 326px;
		@include rwd(800){
			bottom:42px;
		}
		@include xl{
			transform:translateX(-8%);
		}
		@include xxl{
			transform:translateX(-3%);
		}
	}
}

@import '../assets/scss/styles.scss';

#notFoundPage{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;

  .returnButton{
    margin-top: 50px;
    display: flex;
    justify-content: center;
  }
}

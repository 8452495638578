@import '../../../../../assets/scss/styles.scss';

.calendarSessionLeaderboard{
	position:fixed;
	top:46px;
	bottom:0;
	left:0;
	width:100vw;
	background:white;
	box-shadow: 2px 0px 15px rgba(0, 0, 0, 0.07);
	opacity: 1;
	z-index: 100000;
	transition: left 200ms ease-out, opacity 200ms ease-out;
	overflow-y: auto;

	@include rwd(800){
		top:54px;
	}

	@include xl{
		left: $sidebarSize3;
		right:0;
		width:unset;
	} 

	@include xxl{
		left:$sidebarSize4;
		// background:orange;
	}

	@include rwd(1600){
		left:calc(50vw + 130px); 
	}

}

.calendarSessionLeaderboardOut{
	opacity:0;
	left:100vw;
}
@import '../../../../assets/scss/styles.scss';

#settingsPage {
	.settingsProfile {
		// background:green !important;
		// height: 800px;
		flex: 1;
		// padding-bottom:60px;

		// > h1 {

		// }

		.settingsProfileWrapper {
			max-width: 380px;
			margin-left: auto;
			margin-right: auto;

			@include rwd(700){
				max-width: 450px;
			}

			@include rwd(800){
				margin-left: unset;
				margin-right: unset;
			}

			> .avatarSettings {
				display: flex;
				align-items: center;
				// margin-top: 40px;

				> .avatar {
					img {
						// width:100%;
						// height:100%;
						// object-fit: cover;
					}
				}

				> .actions {
					margin-left: 26px;
					display: flex;
					flex-direction: column;
					align-items: flex-start;

					>.imgUploadLabel{
						padding:0;
						display: flex;
						justify-content: flex-start;
						cursor:pointer;
						text-align: unset;
						color: $grey1;
						transition: all 120ms ease-in-out;

						>input{
							visibility: hidden;
							display: none;
						}

						svg {
							stroke: $grey1;
							stroke-width: 2;
							width: 12px;
							height:12px;
							margin-right: 10px;
							transition: all 120ms ease-in-out;
						}

						&:hover{
							color: $grey1Hover;
							svg{
								stroke:$grey1Hover;
							}
						}
					}

					// button {
					// 	display: flex;
					// 	align-items: center;
					// 	color: $grey1;

					// 	&:nth-child(2) {
					// 		margin-top: 14px;
					// 	}

					// 	svg {
					// 		stroke: $grey1;
					// 		width: 14px;
					// 		stroke-width: 2;
					// 		fill: none;
					// 		margin-right: 8px;
					// 	}
					// }
					// .delete {
					// 	color: $error;
					// 	svg {
					// 		stroke: $error;
					// 	}
					// }
				}
			}

			.profileForm {
				display: block;
				margin-top: 50px;

				.selectInput, .textInput{
					margin-top:10px;

				}

				.doubleInput{
					@include rwd(700){
						display: flex;
						justify-content: space-between;

						.textInput{

							&:nth-child(2){
								margin-left:15px;
							}
						}
					}
				}
                >.actions{
                  margin-top:35px;

                  @include rwd(700){
                    width:100%;
                    // background:red;
                    display: flex;
                    justify-content: flex-start;
                  }

                  .btn{
                    padding:12px 30px !important;

                    &:nth-child(2){
                      margin-left:15px;
                    }
                  }
                }
			}
		}
	}
}

@import '../../../../assets/scss/styles.scss';

.channelsList{
	display: flex;
	flex-direction: column;

	>.channel{
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding:13px 20px;
		box-sizing: border-box;
		cursor:pointer;
		// background:yellow;
		width:100%;

		>.avatar{
			height:100%;
			// background:orange;
			>.progIcon, >.userIcon{
				width:40px !important;
				height:40px !important;

				@include rwd(1750){
					width:44px !important;
					height:44px !important;
				}

				@include qhd{
					width:48px !important;
					height:48px !important;
				}
			}

			>.progIcon{
				overflow:hidden;
				border-radius: 10px;
				img{
					width:100%;
					height:100%;
					object-fit: cover;
				}
			}
		}

		>.texts{
			flex:1;
			width:100%;
			height:100%;
			// background:green;
			margin-left:10px;
			overflow: hidden;

			@include rwd(500){
				margin-left:18px;
			}
			// @include xl{margin-left:10px;}

			>h1{
				color:$grey1;
				line-height: 15px;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
				// background:purple;
				padding:3px 0;
			}
			>p{
				display: block;
				box-sizing: border-box;
				height:18px;
				padding:3px 0;
				color:$grey2;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
				// background:orange;

				@include rwd(1750){
					margin-top:3px;
				}
			}
		}

		>.infos{
			width:55px;
			margin-left:10px;
			// background:purple;
			display: flex;
			flex-direction: column;
			align-items: flex-end;

			@include rwd(500){
				margin-left:18px;
			}

			@include rwd(1750){margin-left:22px;}
			@include qhd{margin-left:28px;}
			// @include xl{margin-left:10px;}

			>.dateNewMessage{
				color:$grey1;
			}

			>.notificationNbWrapper{
				height:18px;
				min-width: 18px;

				>.notificationNb{
					margin-top:5px;
					background:$primary;
					color:white;
					border-radius: 10000px;
					padding:0 6px;
					padding-top:1px;
					box-sizing: border-box;
					font-size:9px;
					height:100%;
					display: flex;
					align-items: center;
					justify-content: center;

					@include qhd{
						font-size: 10px;
					}
				}
			}

		}
	}


}


.channelsListEmpty{
	padding:13px 20px;
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-top:20px;
	text-align: center;
	color:$grey1;

	>img{
		width:35%;
	}

	>h1{
		margin-top:25px;
	}

	>p{
		margin-top:12px;
		line-height: 18px;
		max-width: 200px;
        color:$grey2;
	}

	>.btn{
		margin-top:20px;
	}
}

@import '../../../../assets/scss/styles.scss';


.programsCreatedWidgetWrapper{

	.programLine{
		margin-top:15px;

		@include qhd{
			margin-top:20px;
		}
	}
}

@import '../../../assets/scss/styles.scss';

#programHomePage{
	box-sizing: border-box;
	padding: 28px 26px;
	position:relative;

	@include rwd(860){
		padding-top:40px;
		padding-bottom:60px;
	}
	.programHomePageWrapper{
		display: flex;
		flex-direction: column;
		align-items: center;

		@include rwd(830){
			flex-direction: row;
			justify-content: center;
			align-items: stretch;
		}

		>.column{
			display: flex;
			flex-direction: column;
			align-items: center;

			width:100%;
			max-width: 560px;
			margin-left:auto;
			margin-right:auto;

			&:first-child{margin-top:unset;}

			@include rwd(800){align-items: unset;}
			@include xxl{max-width: 620px;}


		}

		>.column1{
			padding-top:70px;
			@include rwd(830){
				padding-top:90px;
				margin-right:30px;
			}

			@include xl{
				margin-right:50px;
			}

			@include qhd{
				margin-right:70px;
				padding-top:100px;
			}
		}

		>.column2{
			@include rwd(830){
				max-width: 330px;
				// margin-left:30px;
				padding-top:12px;
			}
			@include lg{
				max-width: 325px;
			}
			@include xl{
				margin-left:50px;
				padding-top:64px;
				max-width: 380px;
			}
			@include xxl{
				max-width: 420px;
			}
			@include qhd{
				margin-left:70px;
				padding-top:74px;
				max-width: 460px;
			}
		}

		.separator{
			width:1px;
			background:$grey4;
		}

		.programHomeDetails{
			margin-top:38px;

			@include qhd{
				margin-top:50px;
			}
		}

		.programSessions{
			margin-top:20px;
			@include rwd(500){
				margin-top:50px;
			}
			@include xl{
				margin-top:unset;
			}
		}
		.programPromotion{
			margin-top:40px;
			@include rwd(500){
				margin-top:50px;
			}
			@include xl{
				margin-top:unset;
			}
		}
	}
  .modalSession{
    padding:60px 20px;
    min-width: none;
    max-width: 300px;
    text-align: left;
    >h1{
      color:$grey1;
      margin-bottom: 35px;
      font-size: 24px;
      margin-left: 10px;
      margin-right: 10px;
    }

    .bodyText1{
      font-size: 16px;
      line-height: 25px;
      margin-bottom: 20px;
      color:$grey1;
      margin-left: 10px;
      margin-right: 10px;
    }

    >.actions{
      display: flex;
      justify-content: space-between;
      margin-top: 35px;
      >.btn{
        margin-left: auto;
        margin-right: auto;
        line-height: 25px;
        font-size: 14px;
        min-width: 200px;
        //   padding:0;
      }
    }
  }
}



@import '../../assets/scss/styles.scss';

#chatPage{
	.chatApp{
		@include rwd(800){
			display: flex;
			flex-direction: row;
			align-items: flex-start;
			flex-grow: 1;
		}
		.column{
			@include xl{
				// background:blue;
			}
		}

		.column1{
			@include rwd(800){
				width:320px;
			}

			@include rwd(1750){
				width:350px;
			}

			@include qhd{
				width:390px;
			}
		}
		.column2{
			@include rwd(800){
				flex:1;
			}
		}
		.column3{
			@include rwd(1500){
				width:250px;
			}

			@include rwd(1800){
				width:300px;
			}
		}
	}
}
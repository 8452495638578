@import '../../../assets/scss/styles.scss';

.chatDiscussion{
	position:fixed;
	top:96px;
	bottom:0;
	left:0;
	right:0;
	display:flex;
	flex-direction: column;
	z-index: 20;
	background:#f9fbff;
	z-index:40;

	@include rwd(800){
		position:unset;
		width:100%;
		height:calc(100vh - 106px);
	}

	@include rwd(1500){
		margin-top: unset;
		height:calc(100vh - 54px);
	}

	.discussionZone{
		flex:1;
		position: relative;
		height: 100%;
		.discussionZoneWrapper{
			position: absolute;
			top:0px;
			right:0;
			left:0;
			bottom:0;
			overflow-y:scroll;
		}
	}

	.inputZone{
		background:white;
	}
}
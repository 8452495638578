@import '../../../../assets/scss/styles';

.programStepInfos {
	> .logoProgram {
		display: flex;
		align-items: center;
		margin-top: 7px;
		> .preview {
			width: 50px;
			height: 50px;
			overflow: hidden;
			border-radius: 10px;
			box-shadow: 0px 6px 12px 2px rgba(23, 23, 27, 0.08);

			@include qhd {
				width: 60px;
				height: 60px;
			}

			>img{
				width:100%;
				height:100%;
				object-fit: cover;
			}
		}

		> .actions {
			flex:1;
			margin-left: 20px;
			display: flex;
			flex-direction: column;
			align-items: flex-start;

			>.imgUploadLabel{
				padding:0;
				display: flex;
				justify-content: flex-start;
				cursor:pointer;
				text-align: unset;
				color: $error;
				transition: all 120ms ease-in-out;

				>input{
					visibility: hidden;
					display: none;
				}

				svg {
					stroke: $error;
					stroke-width: 2;
					width: 12px;
					height:12px;
					margin-right: 10px;
					transition: all 120ms ease-in-out;
				}

				&:hover{
					color: $errorHover;
					svg{
						stroke:$errorHover;
					}
				}
			}

			> button {
				display: flex;
				align-items: center;
				color: $grey1;
				transition: all 120ms ease-in-out;
				margin-top:6px;

				svg {
					stroke: $grey1;
					stroke-width: 2;
					width: 12px;
					height:12px;
					margin-right: 10px;
					transition: all 120ms ease-in-out;
				}

				&:hover {
					color: $grey2;
					svg {
						stroke: $grey2;
					}
				}
			}
		}
	}

	> form {
		margin-top: 44px;
		@include qhd {
			margin-top: 60px;
		}
        .helper{
          color:$grey2;
          margin-bottom:40px;
          margin-top: -15px;
          line-height: 20px;
        }
		.textInput {
			margin-top: 20px;
			@include qhd {
				margin-top: 30px;
			}
		}
		.textarea {
			margin-top: 20px;
			@include qhd {
				margin-top: 30px;
			}
		}
      > .keywords {
        width: 100%;
        margin-top: 4px;
        @include qhd {
          margin-top: 10px;
        }
        > .actions {
          display: flex;
          justify-content: space-between;
          align-items: center;
          h2 {
            color: $grey1;
          }
        }

        > .list {
          display: flex;
          flex-wrap: wrap;
          margin-left: -12px;
          > .keyword {
            background: $grey4;
            border-radius: 15px;
            padding: 6px 16px;
            margin-left: 10px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            color: $grey2;

            margin-top: 12px;

            > button {
              margin-left: 5px;
              svg {
                stroke: $grey2;
                stroke-width: 3;
                width: 10px;
              }
            }
          }
        }

        .emptyKeywords{
          color:$grey2;
          margin-top:10px;
          line-height: 20px;
        }

        .modalWindow {
          background: #ffffff;
          box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.25);
          border-radius: 10px;
          padding: 28px 38px;
          max-width: 500px;
          // width: 94%;
          box-sizing: border-box;

          .modalKeyword {
            > h1 {
              color: $grey1;
            }

            > .textInput {
              margin-top: 40px;
            }

            > .actions {
              width: 100%;
              display: flex;
              justify-content: space-between;
              align-items: center;
              margin-top: 10px;

              > button {
                color: $grey2;
                transition: color 120ms ease-in;
                &:nth-child(2) {
                  color: $primary;
                }

                &:hover {
                  color: #9aa2b6;
                  &:nth-child(2) {
                    color: #00ffe2;
                  }
                }
              }
            }
          }
        }
      }
      >.actions{
        // background:red;
        text-align: left;
        display: flex;
        justify-content: flex-start;
        margin-top: 20px;

        >button{
          &:first-child{
            margin-right:10px;
          }
        }
      }
	}
}

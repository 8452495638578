@import '../../../assets/scss/styles.scss';

.navbarSearchModal {
	position: fixed;
	height: 380px;
	left: 24px;
	right: 24px;
	top: 42px;
	z-index: 10001;
	border-radius: 5px;
	background: #ffffff;
	box-shadow: 0px 10px 20px rgba(108, 121, 191, 0.1);

	transition: all 300ms ease-in-out;

	@include rwd(500) {
		width: 350px;
		left: unset;
	}

	@include rwd(800) {
		top: 50px;
		right: 28px;
	}

	@include xl {
		width: 390px;
		right: 28px;
	}
	@include xxl {right: 32px;}
	@include qhd {
		right: 36px;
		width: 420px;
	}

	.navbarSearchModalOptions {
		display: flex;
        flex-direction: column;
		align-items: center;
		padding: 12px 0;
	}

	.contentSimu{
		position: absolute;
		bottom:0;
		top:48px;
		left:0;
		right:0;
		overflow-y: auto;
		border-bottom-left-radius: 5px;
		border-bottom-right-radius: 5px;
		box-sizing: border-box;

		padding:10px 32px 20px 32px;
	}
}

@import '../../../assets/scss/styles.scss';

.css-1okebmr-indicatorSeparator{
	display:none;
}


.selectInput{
	position:relative;
	width:100%;
	height:65px;
	z-index: 20;

	.errorMessage{
		width:100%;
		text-align: right;
		color:$error;
		margin-top:4px;
		height:14px;
		// overflow: hidden;
	}
	
}
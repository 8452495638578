@import '../../../../assets/scss/styles.scss';

.settingMobileMenu{
	position: absolute;
	top:46px;
	right:26px;
	
	z-index:90;
	cursor:pointer;

	.selected{
		display: flex;
		align-items: center;
		justify-content: flex-end;
		color:$grey1;
		text-align: right;

		svg{
			margin-left:10px;
			width:15px;
			stroke: $grey1;
			stroke-width:2;
			fill:none;
			padding-top:1px;
		}
	}

	.menuContainer{
		background:white;
		box-shadow: 0px 0px 10px 4px rgba(0,0,0,0.03);
		padding:12px 20px;
		margin-top:15px;

		transform: translateY(-20px);
		opacity:0;

		.item{
			
		}

		.separator{
			width:100%;
			height:1px;
			background:$grey3;
			margin-top:20px;
			margin-bottom:20px;
		}

		.disconnect{
			color:$error;
			transition: color 120ms ease-in-out;
			padding-left:25px;

			&:hover{
				color:$errorHover;
			}
		}

	}

}
@import '../../../../assets/scss/styles.scss';

.informationsItem{
	display: flex;
	.icon{
		svg{
			width:13px;
			stroke: $grey2;
			stroke-width:3;
			fill:none;
		}
	}

	.texts{
		margin-left:7px;
		.title{color:$grey2;}
		h2{margin-top:8px;}
	}
}
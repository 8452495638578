@import '../../../../assets/scss/styles.scss';

.wodLine {
	padding: 16px 20px;
	background: $grey4;
	border-radius: 10px;
	margin-top: 13px;
	color: $grey1;
	cursor: pointer;

	> h2 {
		color: $grey2;
		margin-bottom: 5px;
	}
	> h1 {
	}

	> .scoresList {
		display: flex;
		margin-top: 16px;
		flex-wrap: wrap;
		gap: 5px;

		> .score {
			background: $secondary;
			border-radius: 100px;
			color: white;
			padding: 5px 10px;
		}
	}
}

.focusWod{
  border: 2px solid $secondary;
}

@import '../../../../assets/scss/styles.scss';

.dayCellule {
	box-sizing: border-box;
	vertical-align: top;
	position: relative;
	width: 100%;

	> .dayCelluleWrapper {
		background: white;
		padding: 9px;
		box-sizing: border-box;
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;

		> .title {
			color: $grey1;
			padding: 11px 2px 4px 2px;
		}

		> .titleToday {
			color:$secondary !important;
			font-weight: bold;
			
		}

		> .sessionsListe {
			margin-top: 0px;
			padding-bottom: 20px;
		}

		&:hover {
			z-index: 30;
		}

		>.btnAddSessionWrapper{
			display: flex;
			justify-content: flex-end;
			padding:4px 0 7px 0;
		}

		>.btnAddSessionWrapperMonth{
			position:absolute;
			top:15px;
			right:10px;
		}

		.buttonAddSession{
			width:20px;
			height:20px;

			svg{
				width:10px;
			}
		}


	}

	> .dayCelluleWrapperSelected {
		left: -7px;
		right: -7px;
		top: -7px;
		bottom: -7px;
		z-index: 31;
		border: 4px solid $secondary;
		border-radius: 10px;
		box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.1);
	}
}

.dayCelluleMonth {
	height: 140px;
}

.dayCelluleWeek {
	height: 400px;
}

@import '../../../../../assets/scss/styles.scss';

.timerChronoApp {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	height: 100%;
	position: relative;
	overflow: hidden;

	@include xl{
		min-height: 480px;
	}

	> .infos {
		// height: 50px;
		width: 100%;
		// background: rgb(31, 199, 39);

		>.infosText{
			display: flex;
			flex-direction: column;
			align-items: center;
			color:white;
			margin-top: 30px;

			@include xl{
				flex-direction: row;
				align-items: center;
				justify-content: space-evenly;
				margin-top: 50px;
			}


			
			>h1{
				font-weight: 700;
				font-size: 26px;
				margin-top: 14px;


				&:first-child{
					margin-top: unset;
				}

				

				@include xl{
					margin-top: unset;
					font-size: 50px;
				}

				@include rwd(1600){
					font-size: 60px;
				}
			}
		}
	}

	> .timer {
		position: absolute;
		// height:200px;
		width: 100%;
		// background:rgb(199, 160, 31);
		top: 50%;
		transform: translateY(-50.5%);
		display: flex;
		align-items: center;
		justify-content: center;

		> h1 {
			font-family: 'Roboto', sans-serif;
			font-weight: 700;
		}



		> .timerValue {
			// font-size:150px;
			font-size: 35vw;
			color: white;



			@include rwd(650){
				font-size:28vw;
			}

			@include lg {
				font-size: clamp(16px, 23vw,300px);
			
			}

			@include rwd(1600){
				font-size: clamp(16px, 22vw,380px);
			}

			// @media all and (max-height:360px){
			// 	font-size: 15vh;
			// }

			@media all and (max-height:400px){
				font-size: 20vh;
			}



			// @media all and (max-height:700px) and (min-height:401px){
			// 	font-size: 30vh;
			// }
		}

		> .countdownPrestart {

			color: $primary;

		}

		> .timerValuePause {
			color: $warning;
		}

		> .timerValueSetPause {
			color: $secondary;
		}

		> .timerValueEnd {
			color: $error !important;
		}
	}

	> .actions {
		// height:50px;
		width: 100%;
		// position:relative;
		// background:rgb(109, 31, 199);

		padding: 0 15px 20px 15px;
		box-sizing: border-box;

		@include rwd(380) {
			padding: 0 20px 20px 20px;
		}

		@include rwd(420) {
			padding: 0 26px 20px 26px;
		}

		@include xl{
			padding: 0 45px 30px 45px;

		}

		>.top{
			display: flex;
			flex-direction: column;
			align-items: center;
			margin-bottom: 26px;

			>h1{
				color:white;
				margin-bottom: 20px;
				text-transform: uppercase;

				@media all and (max-height:700px){
					margin-bottom: 20px;
				}

				
				@include xl{
					font-size: 26px;
				}
			}

			>button{
				text-transform: uppercase;
				width:100%;
				max-width:300px;
				padding-top:15px;
				padding-bottom:15px;
				// font-size:15px;
			}
		}

		> .bottom {
			position: relative;
			display: flex;
			justify-content: space-between;
			align-items: center;
			height:50px;

			> .left {
				// background:orange;
				display: flex;
				align-items: center;
			}

			> .center {
				position: absolute;
				left: 50%;
				transform: translateX(-50%);

				// background:rgb(168, 34, 34);
				display: flex;
				align-items: center;
				gap: 5px;
				// height:50px;
				// width:80px;

				@include rwd(380) {
					gap: 7px;
				}

				> .btnStop {
					> svg {
						stroke: $warning !important;
					}
				}

				> .btnPause {
					> svg {
						stroke: $grey2 !important;
					}
				}

				> .btnStopPause {
					padding: 10px;
					border-radius: 50% !important;

					> svg {
						width: 23px;
						stroke: white;
						stroke-width: 2;
					}

					@include rwd(380) {
						padding: 11px;
						> svg {
							width: 26px;
						}
					}

					@include rwd(410) {
						padding: 13px;
						> svg {
							width: 28px;
						}
					}

					@include xl{
						padding: 15px;
						> svg {
							width: 32px;
						}
					}
				}
			}

			> .right {
				> .btnMaximize {
					padding: 10px;
					border-radius: 50% !important;

					> svg {
						width: 16px;
						stroke: white;
						stroke-width: 2;
					}

					@include rwd(380) {
						padding: 11px;
						> svg {
							width: 18px;
						}
					}

					@include rwd(410) {
						padding: 13px;
						> svg {
							width: 20px;
						}
					}

					@include xl{
						padding: 16px;
						> svg {
							width: 24px;
						}
					}
				}
			}
		}

		.btnTimer {
			padding: 12px 8px;
			background: #294872;
			font-size: 13px;
			border-radius: 10px;
			color: white;
			transition: background 100ms ease-out;

			@include rwd(380) {
				padding: 12px 10px;
				font-size: 14px;
			}

			@include rwd(410) {
				padding: 14px 12px;
				font-size: 15px;
			}

			@include xl{
				padding: 16px 20px;
				font-size: 16px;
			}

			&:hover {
				background: #2c5a8a;
			}
		}

		.btnTimerDesactive {
			background: unset !important;
			&:hover {
				background: unset;
			}
		}

		.execiseTextButton{
			position:absolute;
			padding: 13px 20px 13px 16px;
			border-top-left-radius:14px;
			border-bottom-left-radius:14px;

			background: #294872;
			top:20px;
			right:0px;
			cursor:pointer;

			>svg{
				width:22px;
				height:22px;
				stroke:white;
				stroke-width: 2;
			}

		}
	}
}

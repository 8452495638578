@import '../../../../assets/scss/styles';

.programHomeDetails {
	width: 100%;
	display: flex;
	flex-direction: column;

	@include rwd(520) {
		flex-direction: row;
		align-items: center;
	}

    .clickContainer {
      cursor: pointer;
      &:hover {
        transform: scale(1.05);
      }
    }
	.detailContainer {
		background: $grey4;
		box-sizing: border-box;
		padding: 16px 24px;
		border-radius: 20px;
		width: 100%;

		display: flex;
		flex-direction: row;
		align-items: center;

		.roundIcon {
			width: 56px;
			height: 56px;
			background: #e0f0fb;
			border-radius: 2000px;
			overflow: hidden;
			display: flex;
			align-items: center;
			justify-content: center;
			svg {
				stroke: $secondary;
				stroke-width: 2;
				fill: none;
				width: 18px;
			}
		}

		.infosProgram {
			flex: 1;
			display: flex;
			justify-content: space-between;
			align-items: center;
			margin-left: 20px;
			.left {
				h1 {
					color: $grey2;
				}
				h2 {
					color: $grey1;
					margin-top: 8px;
				}
			}

			.right {
				svg {
					width: 20px;
					stroke: $grey1;
				}
			}
		}
	}

	.presentation {
		@include rwd(520) {
			width: 40%;
			max-width: 180px;
		}

		@include qhd {
			max-width: 200px;
		}

		.coach {
			@include rwd(520) {
				flex-direction: column;
				padding: 24px;
			}

			.infosCoach {
				flex: 1;

				display: flex;
				flex-direction: column;
				align-items: flex-start;
				margin-left: 20px;

				@include rwd(520) {
					align-items: center;
					margin-left: unset;
					transform: translateY(-10px);
				}

				.coachDeco {
					background: $primary;
					border-radius: 2000px;
					padding: 4px 10px;
					color: white;
				}

				h1 {
					color: $grey1;
					margin-top: 10px;
					line-height: 20px;
					@include rwd(520) {
						text-align: center;
					}
					@include qhd {
						line-height: 22px;
						margin-top: 12px;
					}
				}

				h2 {
					color: $grey2;
					margin-top: 6px;
					@include qhd {
						margin-top: 9px;
					}
				}
			}
		}
	}

	.infos {
		display: flex;
		flex-direction: column;

		@include rwd(520) {
			flex: 1;
			margin-left: 20px;
		}

		.members {
			margin-top: 12px;
			@include rwd(520) {
				margin-top: unset;
			}
		}
		.duration {
			margin-top: 12px;
			@include rwd(520) {
				margin-top: 16px;
			}
		}
	}
}

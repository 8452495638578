@import '../assets/scss/styles.scss';

#globalLayout{

	>.app{
		>.content{
			box-sizing: border-box;
			overflow-x: hidden;
			margin-top:46px;
			// padding-top:46px;
			// background:red;
			
			
	
			@include rwd(800){
				margin-top:54px;
				// padding-top:54px;
			}
	
			@include xl{
				margin-left:260px;
			}
	
			@include xxl{
				margin-left:300px;
			}
		}
	}
	




}
@import '../../../../assets/scss/styles.scss';

.newsWrapper{
	width:100%;

	.newsList{
		margin-top:28px;
		display: grid;
		grid-template-columns: 1fr 1fr;
		column-gap: 20px;
		row-gap: 30px;
		

		@include rwd(520){
			grid-template-columns: 1fr 1fr 1fr;
		}

		@include xxl{
			column-gap: 30px;
			row-gap: 30px;
		}
	}
}
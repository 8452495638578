@import '../../../../../assets/scss/styles.scss';

.calendarSessionLeaderboardTemplate {
	padding: 26px 24px 40px 24px;
	box-sizing: border-box;

	// overflow-y: auto;
	> .navbar {
		color: $grey1;
		display: flex;
		align-items: center;
		justify-content: space-between;

		> .left {
			> button {
				display: flex;
				gap: 10px;
				align-items: center;
				cursor: pointer;

				> svg {
					width: 18px;
					stroke: $grey1;
					stroke-width: 2;
				}
			}
		}

		> .right {
			> button {
				cursor: pointer;
				> svg {
					width: 22px;
					stroke: $grey1;
					stroke-width: 2;
				}
			}
		}
	}

	> .exerciseInfos {
		margin-top: 26px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		> .left {
			> h1 {
				color: $grey1;
			}
		}

		> .right {
			> .btn {
				@include rwd(440) {
					padding: 8px 16px;

					> .btnIcon {
						svg {
							width: 16px;
							stroke-width: 3;
							fill: none;
							padding-bottom: 1px;
						}
					}
				}
			}
		}
	}

	> .actions {
		display: flex;
		gap: 5px;
		flex-wrap: wrap;
		margin-top: 26px;
		> button {
			border-radius: 20px;
			padding: 8px 16px;
			// margin-left: 10px;
		}
	}

	> .scoreTable {
		color: $grey1;
		margin-top: 25px;
		> .titleBar {
			display: flex;
			align-items: center;
			gap: 14px;
			background: $grey4;
			border-radius: 10px;
			padding: 13px 10px;
			> .position {
				width: 40px;
				// background:red;
				display: flex;
				justify-content: center;

				> svg {
					width: 15px;
					stroke: $grey1;
					stroke-width: 2;
				}
			}
			> .adherants {
				width: 160px;
			}
			> .pseudo {
				width: 150px;
			}
			> .score {
				width: 140px;
			}
		}

		> .scoreList {
			margin-top: 14px;
            > .noScore{
              display: flex;
              justify-content: center;
            }
			> .scoreLine {
				display: flex;
				align-items: center;
				justify-content: space-between;
				padding: 13px 10px;
				// background:$grey3;
				border-radius: 10px;
				@include rwd(800) {
					padding: 18px 10px;
				}
				> .left {
					display: flex;
					gap: 14px;
					align-items: center;

					> .position {
						width: 40px;
						text-align: center;
					}

					> .infos {
						@include rwd(800) {
							display: flex;
							gap: 14px;
							align-items: center;
							// background:cyan;
						}
						> .adherants {
							display: flex;
							align-items: center;
							// background:red;

							> h1 {
								margin-left: 8px;
								text-transform: capitalize;
							}

							@include rwd(800) {
								width: 160px;
							}
						}
						> .pseudo {
							margin-top: 7px;
							// background:green;
							@include rwd(800) {
								width: 150px;
								margin-top: unset;
							}
						}
						> .score {
							margin-top: 8px;
							// background:pink;
							@include rwd(800) {
								width: 140px;
								margin-top: unset;
							}
						}
					}
				}

				> .right {
					padding-right: 16px;
					width: 15px;
					height: 15px;
					box-sizing: border-box;
					position: relative;
					> button {
						z-index: 3;
						position: absolute;
						top: 0;
						left: 0;
						width: 100%;
						height: 100%;
					}

					> svg {
						stroke: $grey1;
						stroke-width: 2;
						z-index: 2;
						top: 0;
						left: 0;
						position: absolute;
						width: 100%;
						height: 100%;
					}
				}
			}
		}
	}
}

@import '../../../../../../assets/scss/styles.scss';

.exerciseEditor{
	padding:12px 28px;

	color:$grey1;

	>.editContainer{
		border-radius: 10px;
		overflow: hidden;

		>.scoreEdit{
			background:#FAFBFF;
			box-sizing: border-box;
			padding:17px 24px;

			>h1{
				
			}

			>.checkboxs{
				display: flex;
				flex-wrap: wrap;
				margin-top: 10px;
				gap:12px 15px;
				
			}
		}

		>.exercisesList{
			background:$grey4;
			padding: 24px 21px;

			>.exercisesListWrapper{
				>.blockEdit{
					margin-top: 14px;
					&:first-child{
						margin-top: unset;
					}
				}
			}


		}

		>.actions{
			background:$grey4;

			padding: 0px 21px 24px 21px;
			display: flex;
			flex-direction: column;
			align-items: center;
			gap:10px;
			

			>button, .imgUploadLabel{
				display:flex;
				align-items: center;
				gap:8px;



				>svg{
					width:14px;
					stroke:$grey1;
					stroke-width: 2;
				}

				&:hover{
					color:$grey1Hover;
					>svg{
						stroke:$grey1Hover;
					}
				}

				>input{
					visibility: hidden;
					display: none;
				}
			}

			.imgUploadLabel{
				padding:0;
				display: flex;
				justify-content: flex-start;
				cursor:pointer;


				>input{
					visibility: hidden;
					display: none;
				}
			}


		}

	}

	>.actions{
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: space-between;
		margin-top: 20px;

		@include rwd(550){
			flex-direction: row;
		}

		.btn{
			height:36px;
		}

		>.left{
			>.addTimerButton{
				position:relative;

				>.check{
					position:absolute;
					background:$secondary;
					padding:1px;
					border-radius: 100px;
					left:25px;
					bottom:9px;

					>svg{
						width:8px;
						stroke:white;
						stroke-width: 3;
					}
				}

			}
		}

		>.right{
			display: flex;
			align-items: center;

			margin-top: 15px;

			@include rwd(550){
				margin-top: unset;	
			}

			button{
				&:nth-child(2){
					margin-left: 10px;
				}


			}

		}

	}
}
@import '../../../../../../assets/scss/styles.scss';

.checkboxScore{

	display: flex;
	align-items: center;
	gap: 6px;
	cursor:pointer;

	>.checkbox{
		width:10px;
		height:10px;
		border:1px solid $grey1;
		border-radius: 4px;

		>svg{
			width:10px;
			stroke-width: 3;
			stroke:$grey1;
		}
	}
}
@import '../../../assets/scss/styles.scss';

#settingsPage{

	.menuItem{
		cursor:pointer;
		display: flex;
		align-items: center;
		color:$grey2;
		padding:15px 0;
		
		svg{
			width:15px;
			stroke:$grey2;
			fill:none;

			margin-right:10px;
		}

		&:hover{
			color:$grey1;
			svg{
				stroke:$grey1;
			}
		}
	}

	.selectedItem{
		color:$grey1;

		svg{
			stroke:$grey1 !important;
		}
	}
}

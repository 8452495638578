@import '../../../../../assets/scss/styles.scss';

.timerFormTabata{

	>.roundsRestTime{
		display: flex;
		gap:20px;
		margin-top: 20px;

		> .inputNumberRound{
			flex:1;
		}
		> .inputRestTimeRound{
			flex:1;
		}
	}


	>.timerFormStep{
		margin-top: 30px;

		> .stepWrapper{
			display: flex;
			gap:20px;

			>.inputNumberSet{

			}

			>.inputRestTimeSet{
				flex:1;
			}
	

		}
	}


}
@import '../../assets/scss/styles.scss';

.iconRound{
	display: flex;
	align-items: center;
	justify-content: center;
	background : #e6fcf9;
	width:92px;
	height:92px;
	border-radius: 30000px;

	@include qhd{
		width:105px;
		height:105px;
	}

	svg{
		stroke: $primary;
		stroke-width:2;
		width:40%;

		
	}


}
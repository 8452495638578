@import '../../../../assets/scss/styles.scss';

.programsFollowWidgetWrapper{

	.noProgramFollow{

		.title{
			display: flex;
			align-items: center;
			.icon{
				display: flex;
				align-items: center;
				justify-content: center;
				width:55px;
				height:55px;

				background:#e6fcf9;
				border-radius: 2000px;

				svg{
					width:45%;
					stroke:$primary;
					stroke-width: 2;
					fill:none;
				}
			}
			.titleText{
				width:70%;
				margin-left:19px;
				line-height: 20px;
				color:$grey1;
			}
		}

		p{
			margin-top:24px;
			line-height: 18px;
			width:80%;
			color:$grey2;
		}

		>.actions{
			margin-top: 24px;
			.btn{
				display: block;
				margin-top:6px;
	
				&:first-child{margin-top: unset;}
			}
		}


		.emptyMessage{
			margin-top:20px;
		}

	}

	.programsList{
		.programLine{
			margin-top:15px;

			@include qhd{
				margin-top:20px;
			}
		}

		
	}

}

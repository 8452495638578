@import '../../../assets/scss/styles.scss';

#programSettingsPage {
	box-sizing: border-box;
	padding: 28px 26px;
	position: relative;

	@include rwd(860) {
		padding-top: 40px;
		padding-bottom: 60px;
	}

	.programSettingsPageWrapper {
		margin-left: auto;
		margin-right: auto;
		max-width: 500px;

		@include xl {
			margin-left: unset;
			margin-right: unset;
		}

		> .title {
			color: $grey1;
			margin-top: 40px;
		}

		> .programStepInfos {
			margin-top: 32px;
		}
	}

	> .confidentiality {
		margin-top: 34px;
		max-width: 500px;
		margin-left: auto;
		margin-right: auto;
		display: flex;
		justify-content: space-between;
		flex-direction: column;

		@include rwd(360) {
			flex-direction: row;
		}

		@include xl {
			margin-left: unset;
			margin-right: unset;
		}

		.left {
			display: flex;
			align-items: center;
			> h1 {
				color: $grey1;
			}
			> svg {
				width: 16px;
				stroke: $grey2;
				stroke-width: 2;
				margin-left: 12px;
				padding-top: 1px;
			}
		}

		.right {
			margin-top: 20px;
			@include rwd(360) {
				margin-top: unset;
			}
			.switchButtonContainer {
				display: flex;
				align-items: center;
				cursor: pointer;

				.choice1 {
					margin-right: 12px;
					transition: color 180ms ease-in-out;
				}
				.choice2 {
					margin-left: 12px;
					transition: color 180ms ease-in-out;
				}

				.switchButton {
					width: 38px;
					height: 20px;
					border-radius: 20000px;
					background: #dcf0fc;
					display: flex;
					align-items: center;
					box-sizing: border-box;
					padding: 0 5px;
					position: relative;

					.round {
						width: 14px;
						height: 14px;
						overflow: hidden;
						border-radius: 20000px;
						background: $secondary;
						position: absolute;
						transition: all 120ms ease-in-out;
					}
				}


				.privacyConfirmation{
					padding: 28px 38px;
					width:100%;
					max-width: 490px;
					box-sizing: border-box;

					>h1{
						color:$grey1;
						line-height: 20px;
					}

					>p{
						color:$grey2;
						line-height: 20px;
						margin-top:16px;
					}

					>.actions{
						margin-top: 20px;
						display: flex;
						justify-content: space-between;
						align-items: center;

						>button{
							color:$grey2;
							transition: color 120ms ease-in-out;
							&:nth-child(2){
								color:$primary;
								transition: color 120ms ease-in-out;
							}

							&:hover{
								color:$grey2Hover;
								&:nth-child(2){
									color:$successHover;
								}
							}
						}
					}

				}
			}
		}
	}

	> .actions {
		display: flex;
		align-items: center;
		flex-direction: column;
		justify-content: space-between;
		max-width: 500px;
		margin-top: 50px;
		margin-left: auto;
		margin-right: auto;

		@include xl {
			align-items: flex-start;
			margin-left: unset;
			margin-right: unset;
		}

		> .left {
			display: flex;
			align-items: center;
			justify-content: flex-start;

			width: 100%;

			@include xl {
				justify-content: unset;
				width: unset;
			}

			:nth-child(2) {
				margin-left: 8px;
			}
		}
		> .right {
			margin-top: 50px;

			@include xl {
				// margin-top: unset;
			}
			> button {
				color: $error;
				transition: color 100ms ease-in;
				&:hover {
					color: #eb3700;
				}
			}

			.deleteModal{
				padding: 28px 38px;
				width:100%;
				max-width: 500px;
				box-sizing: border-box;

				>h1{
					color:$grey1;
					line-height: 20px;
				}

				>p{
					color:$grey2;
					line-height: 20px;
					margin-top:16px;
				}

				>.actions{
					display: flex;
					align-items: center;
					justify-content: space-between;
					margin-top: 20px;
					>button{
						color:$grey2;
						transition: color 120ms ease-in-out;
						&:nth-child(2){
							color:$error;
							transition: color 120ms ease-in-out;
						}

						&:hover{
							color:$grey2Hover;
							&:nth-child(2){
								color:$errorHover;
							}
						}
					}
				}
			}
		}
	}
}

@import '../../assets/scss/styles.scss';

#forgetPasswordPage{

	.leftLoginWrapper{
		margin-bottom:50px;

		@include xl{
			margin-bottom:120px;
		}
	}

	.rightLoginWrapper{
		background-image:url('../../assets/img/login-cover-opti.jpg');
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center center;
	}
}
@import '../../../../../assets/scss/styles.scss';

.timeChronoAppExerciseModal {
	position: absolute;
	top: 0px;
	// left:0px;
	bottom: 0px;
	right: 0px;
	width: 100%;

	background: #294872;
	z-index: 10000000000000;

	display: flex;
	align-items: center;
	justify-content: center;
	transition: all 200ms ease-out;

	@include rwd(800) {
		top: 0px;
		width: 400px;
	}

	@include xl {
		width: 520px;
	}

	> .modalContent {
		position: relative;
		display: flex;
		width: 100%;
		height: 100%;
		flex-direction: column;
		overflow-y: auto;
		box-sizing: border-box;
		padding: 30px;

		> .text {
			word-break: break-word;
			white-space: pre-wrap;
			-moz-white-space: pre-wrap;
			color: white;
			line-height: 25px;
		}

		> .imageExercise {
			margin: unset;
			width: 250px;
		}

		> .contentBloc {
			margin-top: 20px;
			margin-bottom: 20px;
		}

		> .closeButton {
			position: absolute;
			right: 30px;
			top: 30px;
			cursor: pointer;
			> svg {
				width: 30px;
				stroke-width: 2;
				stroke: white;
			}
		}
	}
}

.timeChronoAppExerciseModalOut {
	// opacity:0;
	right: -100% !important;
}

@import '../../assets/scss/styles.scss';

.loginForm {

	.formContainer {
		.textInput{
			margin-top:20px !important;
		}

		>.actions {
			display:flex;
			flex-direction: column;
			width:100%;
			.forgetPassword {
				align-self: flex-end;
				text-align: right;
				a {
					color: $grey2;
					padding-bottom: 2px;
					border-bottom: 1px solid $grey2;

					&:hover {
						color: $grey1;
						border-bottom: 1px solid $grey1;
					}
				}
			}

			.formValidationFrontPage{
				display:flex;
				justify-content: space-between;
				width: 100%;
			}
		}

	}

	
}

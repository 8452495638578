@import '../../../../assets/scss/styles.scss';

.personalRecordWrapper {
	> .selectContainer {margin-top: 16px;}

	> .personalRecordList {
		margin-top: 26px;

		.year {
			margin-top: 24px;

			text-align: center;
			color: $grey2;
			&:first-child {margin-top: unset;}

			.yearText {
				margin-bottom: 10px;
			}
		}

		.recordLine {
			margin-top: 6px;

			@include qhd{
				margin-top:10px;
			}
		}
	}

	// .messageBox {margin-top: 20px;}
}

@import '../../../../assets/scss/styles.scss';

.recordLine{
	display:flex;
	justify-content: space-between;
	align-items: center;
	color:$grey1;
	height:45px;
	box-sizing: border-box;
	background:$grey4;
	border-radius: 10px;

	.left{
		display:flex;
		align-items: center;

		h2{
			width:60px;
			text-align: center;
		}
	}

	.right{
		h2{margin-right:15px;}
	}
}
@import '../../../../assets/scss/styles.scss';

.searchSidebarChat{
	// display: flex;
	// align-items: center;
	// background:orange;
	box-sizing: border-box;
	padding:0 20px;
	width:100%;
	
	>input[type=text]{
		background:$grey4;
		border-radius: 10px;
		border:unset;
		box-sizing: border-box;
		padding: 15px 20px;
		width:100%;
		// width:unset;
		margin-right:5px;

		&::placeholder{
			color:$grey2;
			opacity: 1;
		}
	}

	>.btn{
		// width: 24px !important;
		// height: 24px !important;
		width:auto;
		svg{
			width: 24px !important;
			stroke-width: 2 !important;
			@include qhd{width: 20px !important;}
		}
	}
}
@import '../../../assets/scss/styles.scss';

.textInput{
	width:100%;

	box-sizing: border-box;
	position: relative;

	label{
		display:block;
		color:$grey2;
		margin-bottom:2px;
		position:absolute;
		top:-15px;
		@include qhd{
			top:-18px;
		}
	}

	.inputZone{
		position:relative;
		display:flex;
		align-items: center;

		input{
			box-sizing: border-box;
			padding:14px 15px;
			border: 1px solid $grey3;
            border-radius: 4px;
			width:100%;
			color:$grey1;

			&:focus{
				border: 1px solid $grey1;
			}

			&::placeholder{
				color:$grey2;
			}
		}

		.inputIcon{
			position:absolute;
			right:12px;
		}
		svg{
			stroke:$grey1;
			stroke-width:2;
			width:16px;
		}

		.inputTextIcon{
			position:absolute;
			right:12px;
			color:$grey1;
		}
	}


	.inputError{
		border: 1px solid $error !important;
		&:focus{
			border: 1px solid $error !important;
		}
	}

	.errorMessage{
		width:100%;
		text-align: right;
		color:$error;
		margin-top:4px;
		height:14px;
		// overflow: hidden;
	}
}

@import '../../../../assets/scss/styles.scss';

.calendarMainSidebarFilters {
	> .programFilter {
		margin-top: 35px;
		&:first-child {
			margin-top: unset;
		}

		> .titleBar {
			display: flex;
			align-items: center;
			justify-content: space-between;
			cursor: pointer;
			> .title {
				color: $grey1;
			}

			> .showButton {
				transition: transform 120ms ease-in-out;
				> svg {
					width: 18px;
					stroke-width: 3;
					stroke: $grey1;
				}
			}

			> .showButtonReverse {
				transform: rotate(-180deg);
			}
		}

		> .programList {
			padding-left: 4px;
			margin-top: 5px;
			overflow: hidden;
			max-height: 1200px;

			transition: opacity 120ms ease-in-out, max-height 100ms cubic-bezier(0, 1, 0, 1);
			padding-bottom: 5px;

			> .filter {
				display: flex;
				align-items: center;
				margin-top: 14px;
				color: $grey1;
				cursor: pointer;

				> .checkbox {
					height: 15px;
					width: 15px;
					border-radius: 4px;
					background: green;
					// margin-left:2px;
					box-sizing: border-box;
					// margin-top:2px;
					display: flex;
					align-items: center;
					justify-content: center;

					> svg {
						width: 10px;
						stroke: white;
						stroke-width: 3;
					}
				}

				> .checkboxOwner {
					background: $primary;
				}

				> .checkboxMember {
					background: $secondary;
				}

				> .checkboxDisable {
					background: #fbfcff !important;
					border: 2px solid $grey3;
					margin-left: unset;
					margin-top: unset;
				}

				> .name {
					margin-left: 10px;
				}
			}
		}
	}
}

@import '../../../../../assets/scss/styles.scss';

.calendarLeaderboardAddScore{
	padding: 28px 38px;

	>h1{

	}

	>form{

		margin-top: 40px;

		>.textInput{
			margin-top: 16px;
		}

		>.time{}

		>.timeInputs{
			display: flex;

			>.textInput{
				margin-top: 16px;

				&:nth-child(2){
					margin-left: 16px;
				}
			}
		}

        >.actions{
          display: flex;
          justify-content: space-between;
          align-items: center;

          >button{
            color:$grey2;
            transition: color 120ms ease-in-out;
            &:nth-child(2){
              color:$primary;
              transition: color 120ms ease-in-out;
            }

            &:hover{
              color:$grey2Hover;
              &:nth-child(2){
                color:$successHover;
              }
            }
          }
        }
	}
}

@import '../../../../assets/scss/styles.scss';

.bubbleChat {
	display: flex;
	align-items: center;
	max-width: 100%;
	overflow-wrap: break-word;

	> .message {
		display: block;
		padding: 13px 15px 4px 15px;
		border-radius: 10px;
		line-height: 18px;
		max-width: 80%;
		display: flex;
		flex-direction: column;
		box-sizing: border-box;

		.messageText {
			overflow-wrap: break-word;
			-webkit-hyphens: auto;
			-ms-hyphens: auto;
			hyphens: auto;
			max-width: 70vw;
			white-space: pre-wrap;


			@include rwd(800) {
				max-width: 47vw;
			}
			@include rwd(1100) {
				max-width: 50vw;
			}
			@include rwd(1500) {
				max-width: 30vw;
			}
			@include rwd(1700) {
				max-width: 38vw;
			}

            a{
              text-decoration: underline;
            }
		}

		> .imgContainer {
			max-width: 120px;
			max-height: 120px;
			display: flex;
			align-items: center;
			justify-content: center;
			overflow: hidden;
			cursor: pointer;

			> img {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}

		.imgModal {
			max-width: 80vw;
			max-height: 80vh;
			img {
				max-width: 80vw;
				max-height: 80vh;
				display: block;
			}
		}

		.messageInfos {
			margin-top: 4px;
			display: flex;
			font-size: 11px;
			opacity: 0.7;

			.name {
				margin-left: 10px;
			}
		}
	}

	> .messageGrey {
		background: $grey3;
		color: $grey1;
	}

	> .messageBlue {
		background: $secondary;
		color: white;
	}

	> .messageMenu {
		> .menuDots {
			position: relative;

			> .menuDotsIcon {
				padding: 10px;
				cursor: pointer;
				> svg {
					fill: $grey1;
					width: 12px;
				}
			}

			.dropdownMenuWrapper {
				> .actions {
					display: flex;
					flex-direction: column;

					> button {
						display: flex;
						align-items: center;
						svg {
							transition: stroke 80ms ease-in-out;
							margin-right: 10px;
							width: 12px;
							stroke-width: 2;
						}
					}

					> .delete {
						color: $error;
						transition: color 80ms ease-in-out;
						svg {
							stroke: $error;
						}

						&:hover {
							color: $errorHover;
							svg {
								stroke: $errorHover;
							}
						}
					}

					> .standard {
						color: $grey1;
						transition: color 80ms ease-in-out;
						svg {
							stroke: $grey1;
						}
						&:hover {
							color: $grey1Hover;
							svg {
								stroke: $grey1Hover;
							}
						}
					}
				}
			}
		}
	}
}

@import '../../../../assets/scss/styles.scss';

.AddWodScoreModal{
	padding:26px 38px;
	min-width: none;
	color:$grey1;
	width:90vw;
	box-sizing: border-box;
	max-width: 500px !important;

	>h1{

	}

	>form{

		margin-top: 40px;

		>.textInput{
			margin-top: 16px;
		}

		>.time{}

		>.timeInputs{
			display: flex;

			>.textInput{
				margin-top: 16px;

				&:nth-child(2){
					margin-left: 16px;
				}
			}
		}
      >.actions{
        display: flex;
        justify-content: space-between;
        align-items: center;

        >button{
          color:$grey2;
          transition: color 120ms ease-in-out;
          &:nth-child(2){
            color:$primary;
            transition: color 120ms ease-in-out;
          }

          &:hover{
            color:$grey2Hover;
            &:nth-child(2){
              color:$successHover;
            }
          }
        }
      }
	}


}

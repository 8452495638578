@import '../../../../assets/scss/styles.scss';

#programHomePage {
	.programPromotion {
		padding: 20px 0;
		max-width: 400px;
		color: $grey1;

		.textPresentation {
			margin-top: 28px;

			p {
				margin-top: 20px;
				line-height: 22px;

				@include qhd {
					line-height: 25px;
				}

				&:first-child {
					margin-top: unset;
				}
			}
		}

		>.actions {
			margin-top: 40px;
            display: flex;
            justify-content: center;
            button{
              min-width: 70%;
            }
		}
		.requestSent,
		.programFull {
			color: $grey1;
			>.iconRound{
				margin-left: auto;
				margin-right: auto;
			}
			h1 {
				margin-top: 30px;
			}
			p {
				margin-top: 16px;
				line-height: 22px;
				@include qhd {
					line-height: 25px;
				}
			}
		}
		.programFull {
			.iconRound {
				background: #fff3dc;
				svg {
					stroke: $warning;
				}
			}
		}

        .modalSession{
          padding:60px 20px;
          min-width: none;
		  max-width: 300px;
		  text-align: center;
          >h1{
            color:$grey1;
            margin-bottom: 35px;
			font-size: 36px;
          }

		  >p{
			  line-height: 20px;
		  }

          .bodyText1{
            font-size: 20px;
            line-height: 25px;
            margin-bottom: 20px;
          }

          >.actions{
            display: flex;
            justify-content: space-between;
            margin-top: 35px;
            >.btn{
				margin-left: auto;
				margin-right: auto;
                line-height: 25px;
                font-size: 14px;
            //   padding:0;
            }
          }
        }
	}
}

@import '../../assets/scss/styles.scss';

.registerTexts{
	display:flex;
	flex-direction: column;
	justify-content: center;
	height:100%;
	background:rgba(17, 51, 90, 0.8);
	position: relative;

	overflow-y: auto;	

	.registerTextsWrapper{
		position:absolute;
		// background:orange;
		display:flex;
		flex-direction: column;
		justify-content: space-between;
		width:72%;
		max-width: 400px;
		// max-height: 800px;
		height: 580px;
		left:50%;
		transform:translateX(-50%);

		@media screen and (min-height: 950px) {height:680px;}
		@include qhd{
			max-width: 450px;
		}


		color:white;

		.up{

			.title{
				max-width:70%;
				line-height: 28px;
			}

			.paragraphe{
				margin-top:30px;
				line-height: 26px;
				color:$grey3;
				@media screen and (min-height: 950px) {margin-top:40px;}
			}

			.benefitsLists{
				margin-top:20px;
				@media screen and (min-height: 950px) {margin-top:32px;}
				.benefitsList{
					margin-top:30px;
					@media screen and (min-height: 950px) {margin-top:36px;}

					.benefit{
						display: flex;
						align-items: flex-start;
						margin-top:15px;
						@media screen and (min-height: 950px) {margin-top:21px;}
						svg{
							width:14px;
							stroke:$primary;
							stroke-width:2;
							padding-top:3px;

							@include qhd{
								width:17px;
								padding-top:1px;
							}
						}
						p{
							color:$grey3;
							margin-left:10px;
							line-height: 18px;
						}
					}
				}

			}


		}

		.down{
			p{
				color:$grey3;

				a{
					color:$primary;

					&:hover{
						color:#00ffe2;
					}
				}
			}
		}
	}



}
@import '../../../../assets/scss/styles';

.programLine {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	cursor:pointer;

	transition: transform 120ms ease-in-out;

	&:hover{
		transform:scale(1.05);
	}

	.left {
		display: flex;
		align-items: center;
		.logo {
			width: 45px;
			height: 45px;
			border-radius: 10px;
			overflow: hidden;
			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}

		.infos {
			margin-left: 16px;

			h1 {
			}

			.infosDetails {
				display: flex;
				align-items: center;
				margin-top: 8px;

				.infosDetail {
					display: flex;
					align-items: center;
					margin-left: 10px;

					&:first-child {
						margin-left: 0;
					}

					h2 {
						margin-left: 4px;
						color: $grey2;
						font-family: poppinsmedium, sans-serif;
						font-weight: 500;
						padding-top: 1px;
					}

					svg {
						width: 12px;
						stroke: $grey2;
						stroke-width: 3;
					}
				}
			}
		}
	}

	.right {
		.rate {
			display: flex;
			align-items: flex-start;
			svg {
				width: 10px;
				stroke: $secondary;
				stroke-width: 3;
				margin-left: 4px;
			}
		}

		.pseudo {
			color: $grey2;
			line-height: 18px;
		}

      .published {
        color: $primary;
      }
      .notPublished {
        color: $error;
      }
	}
}

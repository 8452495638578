@import '../../../assets/scss/styles.scss';

.textarea{
	width:100%;

	box-sizing: border-box;
	position: relative;

	label{
		display:block;
		color:$grey2;
		margin-bottom:2px;
		position:absolute;
		top:-15px;

		@include qhd{
			top:-18px;
		}
	}

	.inputZone{
		position:relative;
		display:flex;
		align-items: center;
		// background:red;
		width:100%;

		textarea{
			box-sizing: border-box;
			padding:14px 15px;
			border: 1px solid $grey3;
            border-radius: 4px;
			width:100%;
			height:130px;
			color:$grey1;

			&:focus{
				border: 1px solid $grey1;
			}

			&::placeholder{
				color:$grey2;
			}
		}


	}


	.inputError{
		border: 1px solid $error !important;
		&:focus{
			border: 1px solid $error !important;
		}
	}

	.errorMessage{
		width:100%;
		text-align: right;
		color:$error;
		margin-top:4px;
		height:14px;
		// overflow: hidden;
	}
}

@import '../../assets/scss/styles.scss';

.emptyMessage{
	display: flex;
	justify-content: center;

	background: $grey4;
	color:$grey2;
	padding:19px 30px;
	box-sizing: border-box;
	border-radius: 10px;
	align-items: center;
	line-height: 16px;
	text-align: center;

	svg{
		width:12px;
		stroke:$grey2;
		stroke-width: 3;

		@include qhd{
			width:13px;
		}
	}
}
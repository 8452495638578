@import '../../../assets/scss/styles.scss';

.wodBookEdit{
  >.exerciseEditorWrapper{
    max-width: 640px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 30px;

    @include xl{
      margin-right: unset;
      margin-left: unset;
    }
  }
}

@import '../../../../assets/scss/styles.scss';

.calendarMobile{
	width:94%;
	max-width: 350px;
	margin-left: auto;
	margin-right: auto;
	padding-bottom: 100px;

	>.calendarSeletorWrapper{

	}

	>.calendarMobileSessionsListWrapper{
		padding:0 10px;
		margin-top: 30px;
	}

}
@import '../../../../../assets/scss/styles.scss';

.timerEndScreenModal {
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	// background:rgba(36, 205, 134);
	background: $darkBackground;


	> .timeEndScreenModalWrapper {
		// background:orange;
		height: 100%;
		position: relative;
		display: flex;
		flex-direction: column;
		justify-content: space-between;

		@include xl{
			flex-direction: row;
			min-height: 720px;
			
		}

		> .left {

			@include xl{
				// background:cornflowerblue;
				flex:3;
			}

			> .content {
				// background:olive;
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				width: 100%;
				margin-top: 20vh;

				@include md {
					margin-top: 17vh;
				}

				@include xl{
					padding-left:50px;
					align-items: flex-start;
					box-sizing: border-box;
				}

				@include rwd(1370){
					padding-left: 110px;
					margin-top: 20vh;
				}

				@include rwd(1800){
					padding-left: 150px;
				}

				> svg {
					width: 140px;
					@include md {
						width: 170px;
					}

					@include rwd(1370){
						width:190px;
					}
				}
				> h1 {
					margin-top: 20px;
					font-size: 26px;
					color: white;

					@include xl{
						font-size: 44px;
						margin-top: 30px;
						font-weight: 700;
					}

					@include rwd(1370){
						margin-top: 40px;
						font-size: 48px;
					}


				}

				> .catName {
					font-size: 32px;
					color: white;
					margin-top: 20px;
					text-transform: uppercase;

					@include rwd(600) {
						font-size: 40px;
						margin-top: 20px;
					}

					@include xl{
						font-size: 70px;
						font-weight: 700;
					}

					@include rwd(1370){
						font-size: 80px;
						margin-top: 40px;
					}
				}

				> button {
					padding: 14px 45px;
					margin-top: 40px;

					@include rwd(1370){
						margin-top: 60px;
					}
				}
			}
		}

		> .right {

			@include xl{
				flex:2;
			}

			> .laps {
				display: flex;
				flex-direction: column;
				align-items: center;
				
				margin-top: 20px;
				margin-bottom: 20px;

				@include xl{
					// min-height: 80%;
					margin-top: 80px;
					justify-content: center;
				}
				> .lap {
					width: 80%;
					max-width: 500px;
					background: #294872;
					margin-top: 20px;
					border-radius: 10px;
					display: flex;
					align-items: center;
					padding: 20px 30px;
					box-sizing: border-box;
					color: white;
					font-size: 12px;

					@include rwd(385) {
						font-size: 14px;
					}

					@include rwd(600) {
						font-size: 16px;
					}

					@include xl{
						font-size: 13px;
					}

					> .lapStart {
						margin-left: 20px;

						@include rwd(385) {
							margin-left: 24px;
						}

						@include rwd(600) {
							margin-left: 30px;
						}

						@include xl{
							margin-left: 20px;
						}
					}

					> .lapTime {
						margin-left: 12px;
						@include rwd(385) {
							margin-left: 15px;
						}
						@include rwd(600) {
							margin-left: 25px;
						}
						@include xl{
							margin-left: 12px;
						}
					}
				}
			}

			> .actions {
				padding: 20px 20px;
				display: flex;
				flex-direction: row;
				justify-content: center;


				> button {
					padding: 14px 45px;
				}

				@include rwd(600) {
					padding-bottom: 40px;
				}
			}
		}
	}
}

@import '../../assets/scss/styles.scss';

.modal{
	position:fixed;
	top:0;
	left:0;
	bottom:0;
	right:0;
	background:rgba(0,0,0,0.5);
	z-index: 10000000000000;

	display: flex;
	align-items: center;
	justify-content: center;
	transition: opacity 200ms ease-out;

	.modalWindow{
		// width:200px;
		// height:300px;
		max-width:94%;
		overflow:hidden;
		background:white;
		box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.25);
		border-radius: 10px;
		transition: all 170ms ease-out;
		transform-style: preserve-3d;
		
		

		.modalWindowWrapper{
			width:100%;
			max-height: 80vh;
			overflow-y: auto;
			
		}
	}
}


.modalOut{
	opacity:0;
}

.modalWindowOut{
	transform: rotateX(-20deg) scale(0.9);
	// transform: rotate3d(1,2,0,0.2turn);

	// padding:200px;
}
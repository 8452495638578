@import '../../../assets/scss/styles.scss';

.timerApp{
	height:100%;
	width:100%;
	background:$darkBackground;
	overflow-y: auto;
	position:relative;

	scrollbar-width: thin; /* "auto" or "thin" */
	scrollbar-color: #8ca8e9 $darkBackground; /* scroll thumb and track */

	&::-webkit-scrollbar{
		width: 12px;
	}
	&::-webkit-scrollbar-track {
		background: $darkBackground; /* color of the tracking area */
	}
	
	&::-webkit-scrollbar-thumb {
		background-color: #8ca8e9; /* color of the scroll thumb */
		border-radius: 20px; /* roundness of the scroll thumb */
		border: 3px solid $darkBackground; /* creates padding around scroll thumb */
	}
}
@import 'styles.scss';
@import 'fonts.scss';
@import 'texts.scss';
@import 'buttons.scss';

html {
	margin: 0 !important;
	margin-top: 0 !important;
	font-size: 100%;
	// background: rgb(255, 255, 255);
	// -webkit-text-size-adjust: none;

	font-family: Poppins, sans-serif;

	// font-variant: normal;
	// text-transform: none;
	line-height: 1;

	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
	font-smoothing: antialiased;
	font-weight: 400;

	@include no-touch-select;

	@include qhd {
		font-size: 120%;
	}
}

*:focus {
	outline: none;
}

body {
	padding: 0;
	margin: 0;
	max-width: 100%;
	// white-space:pre-wrap;
	// overflow-x: hidden;
	// background:#F9FBFF;
}

a {
	text-decoration: none;
	color: black;
	font-family: Poppins, sans-serif;
}

svg {
	display: block;
}

input::-webkit-input-placeholder {
	line-height: normal !important;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
	margin: 0;
	padding: 0;
	font-weight: unset;
}

button {
	border: none;
	margin: 0;
	padding: 0;
	width: auto;
	overflow: visible;
	position: relative;
	display: inline-block;
	cursor: pointer;
	outline: none;
	border: 0;
	vertical-align: middle;
	text-decoration: none;
	font-size: inherit;

	background: transparent;

	/* inherit font & color from ancestor */
	color: inherit;
	font: inherit;

	/* Normalize `line-height`. Cannot be changed from `normal` in Firefox 4+. */
	line-height: normal;

	/* Corrects font smoothing for webkit */
	-webkit-font-smoothing: inherit;
	-moz-osx-font-smoothing: inherit;

	/* Corrects inability to style clickable `input` types in iOS */
	-webkit-appearance: none;
}

button:focus {
	outline: 0 !important;
}

.disabled-link {
	pointer-events: none;
	pointer: cursor;
}

.noselect {
	-webkit-touch-callout: none; /* iOS Safari */
	-webkit-user-select: none; /* Safari */
	-khtml-user-select: none; /* Konqueror HTML */
	-moz-user-select: none; /* Old versions of Firefox */
	-ms-user-select: none; /* Internet Explorer/Edge */
	user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
}

.svgIcon {
	stroke-linecap: round;
	stroke-linejoin: round;
	fill: none;
	display: block;
	height: auto;
}

.scrollbarThin {
	scrollbar-width: thin; /* "auto" or "thin" */
	scrollbar-color: #e6eaf4 rgb(255, 255, 255); /* scroll thumb and track */
}

.scrollbarThin::-webkit-scrollbar {
	width: 12px; /* width of the entire scrollbar */
}

.scrollbarThin::-webkit-scrollbar-track {
	background: rgb(255, 255, 255); /* color of the tracking area */
}

.scrollbarThin::-webkit-scrollbar-thumb {
	background-color: #979797; /* color of the scroll thumb */
	border-radius: 20px; /* roundness of the scroll thumb */
	border: 3px solid rgb(255, 255, 255); /* creates padding around scroll thumb */
}


.modalValidation{
	padding: 28px 38px;
	width:100%;
	max-width: 400px;
	box-sizing: border-box;
	color:$grey1;
	>h1{
		line-height: 22px;
	}

	>h2{
		margin-top: 20px;
		color:$grey2;
		line-height: 19px;
	}

	>.textInput{
		margin-top: 40px;
	}

	>.actions{
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-top: 28px;

		>.btn{
			padding:0;
		}
	}
}
@import '../../../../assets/scss/styles';

.progAcceptNewMember {
	> h1 {
		color: $grey1;
	}

	> .memberTable {
		margin-top: 20px;

		> .memberLine {
			display: flex;
			justify-content: space-between;
			align-items: center;
			background: $grey4;
			padding: 9px 12px;
			box-sizing: border-box;
			border-radius: 10px;

			&:first-child {
				margin-top: unset;
			}

			> .right {
				display: flex;
				margin-left: 15px;

				.btn {
					margin-left: 10px;
					&:first-child {
						margin-left: unset;
					}
				}
			}
		}

		.removeMemberConfirmation {
			background: #ffffff;
			box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.25);
			border-radius: 10px;
			padding: 28px 38px;
			max-width: 500px;
			box-sizing: border-box;

			h1 {
				color: $grey1;
				line-height: 22px;
			}
			p {
				color: $grey2;
				margin-top: 14px;
				line-height: 18px;
			}

			>.actions {
				width: 100%;
				margin-top: 18px;
				display: flex;
				justify-content: space-between;
				align-items: center;

				button {
					color: $grey2;
					&:nth-child(2) {
						color: $error;
					}
				}
			}
		}
	}
}

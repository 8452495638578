@import '../../../../../assets/scss/styles.scss';

.calendarSessionExercices{
	// height:200px;
	// background:orange;
	// height:100%;

	padding:0 28px;
	flex:1;

	display: flex;
	flex-direction: column;

	>.calendarSessionExercice{
		margin-top: 16px;
		&:first-child{
			margin-top: unset;
		}
	}

	>.btnAddExercise{
		margin-top: 30px;
	}

	>.noExercises{
		// background:red;
		flex:1;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		width:90%;
		max-width: 350px;
		margin-left: auto;
		margin-right: auto;

		@include rwd(800){
			justify-content: unset;
		}

		@include qhd{
			max-width: 420px;
		}

		>.noExercisesWrapper{
			padding-bottom: 10vh;

			@include rwd(800){
				padding-bottom: unset;
				padding-top:10vh;
			}

			>h1{
				text-align: center;
				line-height: 22px;
				color:$grey2;
			}

			>p{
				color:$grey2;
				line-height: 20px;
				margin-top: 20px;
				text-align: center;
			}

			>.actions{
				margin-top: 40px;
				display: flex;
				justify-content: center;
			}
		}
	}
}

@import '../../../../assets/scss/styles.scss';

  .settingsConnect{
    >.settingsPaymentWrapper{
      p{
        color:$grey1;
        margin-top:20px;
        line-height: 22px;
        margin-bottom: 40px;
        // max-width: 380px;

        @include qhd{
          line-height: 24px;
          // max-width: 430px;
        }
      }
    }

  }

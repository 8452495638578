@import '../../../../../assets/scss/styles.scss';

.timerSaveConfigButton{
	display: flex;
	align-items: center;
	justify-content: center;
	gap:10px;
	position:sticky;
	bottom:40px;
	background:$primary;
	border-radius: 10px;
	padding:15px 18px;
	// text-transform: uppercase;

	left:26px;
	right:26px;
	width:calc(100% - 52px);
	box-sizing: border-box;
	transition: background 120ms ease-in-out;
	color:white;

	&:hover{
		background:$primaryHover;	
	}

	svg{
		width:23px;
		stroke:white;
		stroke-width:2;
	}

	@include rwd(400){
		max-width: 348px;
		width:100%;
		margin-left: auto;
		margin-right: auto;
		right:unset;
		left:unset;
	}

	@include rwd(800){
		max-width: 448px;
		padding:18px 18px;
	}
}

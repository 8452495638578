@import '../../assets/scss/styles.scss';
.userIconWrapper{
	position:relative;
	// background:red;
	.userIcon{
		overflow: hidden;
		border-radius: 200000px;
		background:white;
		display: flex;
		align-items: center;
		justify-content: center;
		
		img{
			width:100%;
			height:100%;
			object-fit: cover;
		}
	
		h1{
			width:100%;
			height:100%;
			display: flex;
			align-items: center;
			justify-content: center;
			// font-size: 1em;
			font-weight: 600;
			color:#4c5872;
	
		}
	

	}

	.coachSigle{
		position:absolute;
		background:$primary;
		color:white;
		font-size:11px;
		border-radius: 1000px;
		padding:3px 5px;
		bottom:-10px;
		left:50%;
		transform: translateX(-50%);
	}
}

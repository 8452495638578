@import '../../../../assets/scss/styles.scss';

.profileWidgetWrapper{
	display:flex;
	align-items: center;
	width:100%;

	.infos{
		padding-left:20px;
		box-sizing:border-box;
		line-height: 18px;
	}
}

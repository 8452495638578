@import '../../assets/scss/styles.scss';

.dropdownMenu{
	position:absolute;
	padding:12px 20px;
	border-radius:10px;
	z-index:3000000;
	background:white;
	box-shadow: 0px 0px 10px 4px rgba(0,0,0,0.03);
	transition: transform 200ms ease-out, opacity 200ms ease-out;

	.dropdownMenuWrapper{
		width:100%;
		height:100%;
	}
}

.dropdownMenuOut{
	transform: translateY(-20px);
	opacity:0;
}

.dropdownMenuOutTop{
	transform: translateY(20px);
	opacity:0;
}
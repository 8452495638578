.btn{
	display: inline-block;
	padding: 9px 18px;
	border-radius: 10px;
	display:flex;
	align-items: center;
	justify-content: center;
	box-sizing: border-box;
	cursor:pointer;

	transition:background 100ms ease-in-out;

	.btnIcon{
		svg{
			width: 13px;
			stroke-width: 3;
			fill: none;
			padding-bottom:1px;
		}
	}

	.btnText{
		// margin-left:10px;
	}

}


.btnLarge{
	svg{
		width: 16px !important;
		@include qhd{width: 20px !important;}
	}
}

.btnCentered{
	padding: 9px;
}

.btnDuo{
	.btnText{
		margin-left:10px;
	}
}

.btnReversed{
	flex-direction: row-reverse;
	.btnText{
		margin-left:0px;
		margin-right:10px;
	}
}

.btnRound{
	border-radius: 100000px;
}


// ---- COLORS ----

.btnGrey{
	color:#ABB5CD;
	background:#F2F5FB;

	svg{stroke:#ABB5CD;}
	&:hover{background:#dee1e7;}
}



.btnGreyContrast{
	color:#4C5771;
	background:#F2F5FB;

	svg{stroke:#4C5771;}
	&:hover{background:#dee1e7;}
}

.btnGreen{
	color:#FFFFFF;
	background:#05DBC3;

	svg{stroke:#FFFFFF;}
	&:hover{background:#00FFE2;}	
}

.btnGreenLight{
	color:#FFFFFF;
	background:#37e2cf;

	svg{stroke:#FFFFFF;}
	&:hover{background:#16efd7;}	
}

.btnBlue{
	color:#FFFFFF;
	background:#50b4ee;

	svg{stroke:#FFFFFF;}
	&:hover{background:#0097ee;}	
}

.btnBlueLight{
	color:#FFFFFF;
	background:#73c3f1;

	svg{stroke:#FFFFFF;}
	&:hover{background:#4db5f1;}	
}

.btnBlueExtraLight{
	color:$secondary;
	background:#eef8fe;

	svg{stroke:#FFFFFF;}
	&:hover{background:#4db5f1; color:white;}	
}

.btnRed{
	color:#FFFFFF;
	background:#FF5925;

	svg{stroke:#FFFFFF;}
	&:hover{background:#fb4209;}	
}


// NO BACKGROUND BUTTON
.btnGreyNoBack{
	color:#ABB5CD;


	svg{stroke:#ABB5CD;}
	&:hover{
		color:#9aa4b9;


		svg{stroke:#9aa4b9;}
	}
}

.btnGreenNoBack{
	color:$primary;
	svg{stroke:$primary;}

	&:hover{
		color:$primaryHover;
		svg{stroke:$primaryHover;}
	}
}



// OUTLINE WITHOUT BORDER

.btnGreyLine{
	color:#07DF9E;
	svg{stroke:#07DF9E;}
	&:hover{
		// border: 2px solid #00FFE2;
		box-shadow:inset 0px 0px 0px 2px #00FFE2;
		svg{stroke:#00FFE2;}
	}	
}

.btnGreenLine{
	color:#07DF9E;
	svg{stroke:#07DF9E;}
	&:hover{
		// border: 2px solid #00FFE2;
		box-shadow:inset 0px 0px 0px 2px #00FFE2;
		svg{stroke:#00FFE2;}
	}	
}


.btnRedLine{
	color:#FF5925;
	svg{stroke:#FF5925;}
	&:hover{
		// inline: 2px solid #FF5925;
		box-shadow:inset 0px 0px 0px 2px #fa4812;
		svg{stroke:#fa4812;}
	}	
}



// DISABLE

.btnBlueDisable{
	color:#FFFFFF;
	background:#50b4ee;

	svg{stroke:#FFFFFF;}
	cursor:default;
	// &:hover{background:#0097ee;}
}

.btnGreyDisable{
	color:#ABB5CD;
	background:#F2F5FB;
	cursor:not-allowed;

	svg{stroke:#ABB5CD;}
}



//BUTTON TEXT ONLY

.btnTextRed{
	color:$error;

	&:hover{
		color:$errorHover;
	}
}

.btnTextGreen{
	color:$primary;

	&:hover{
		color:$primaryHover;
	}
}





// BUTTON DROPDOWN
.btnDropdown{
	display: flex;
	align-items: center;
	text-align: left;
	color:$grey1;
	cursor:pointer;
	transition:all 80ms ease-in-out;

	&:hover{
		color:$grey1Hover;
		svg{
			stroke:$grey1Hover;
		}
	}

	svg{
		width:13px;
		stroke:$grey1;
		stroke-width:2.5;
		margin-right:10px;
		transition:all 80ms ease-in-out;
		// background:red;
	}
}

.btnDropdownRed{
	color:$error;
				
	&:hover{
		color:$errorHover;
		svg{
			stroke:$errorHover;
		}
	}

	svg{
		stroke:$error
	}
}

.btnDropdownWarning{
	color:$warning;
				
	&:hover{
		color:$warningHover;
		svg{
			stroke:$warningHover;
		}
	}

	svg{
		stroke:$warning
	}
}

.btnDropdownGreen{
	color:$primary;
				
	&:hover{
		color:$primaryHover;
		svg{
			stroke:$primaryHover;
		}
	}

	svg{
		stroke:$primary
	}
}
@import '../../../../assets/scss/styles.scss';

#settingsPage{
	.settingsLanguage{
		// background:green !important;
		height:800px;
		flex:1;
		

		// h1{
		// 	color:$grey1;
		// 	margin-top:17px;
		// }

		.settingsPageContent{
			.langueItem{
				margin-top:20px;
				display: flex;
				width:270px;
				justify-content: space-between;
				align-items: center;
				cursor:pointer;

				.left{
					display: flex;
					align-items: center;

					.icon{
						svg{
							width:15px;
							stroke-width:2;
							stroke:$grey1;
						}
					}
	
	
	
						.uncheck{
							svg{
								stroke-width:1.2;
								stroke:$grey2;
							}
						}
	
	
	
					.text{
						color:$grey1;
						margin-left:16px;
					}	
				}


				.right{
					display: flex;
					align-items: center;

					.lineDeco{
						width: 125px;
						// height:1px;
						border-top:1px dashed $grey3;
						margin-left:13px;
					}
					.flagIcon{
						margin-left:13px;
						svg{
							width:20px;
							
						}
	
					}

				}

			}
		}

	}
}
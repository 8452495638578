@import '../../../../assets/scss/styles.scss';

.selectedWodTemplate {
	color: $grey1;
	padding: 30px 26px;

	> header {
		display: flex;
		justify-content: space-between;
		align-items: flex-start;

		> .left {
			> h2 {
				color: $grey2;
			}

			> h1 {
				margin-top: 7px;

				> .wodName {
					display: flex;
					align-items: center;

					> .actions {
						margin-left: 17px;
						> button {
							margin-left: 14px;

							&:first-child {
								margin-left: 0px;
							}

							> svg {
								width: 16px;
								stroke-width: 3;
							}
						}
					}
				}
			}
		}

		> .right {
			position: relative;
			margin-right: 30px;

			@include rwd(800) {
				margin-right: unset;
			}

			> button {
				> svg {
					height: 16px;
					stroke: $grey1;
					stroke-width: 4;
					fill: $grey1;
					opacity: 1;
				}

				&:hover {
					> svg {
						stroke: $grey2;
						fill: $grey2;
					}
				}
			}

			> .dropdownMenu {
				.actions {
					padding: 4px 0;
					> button {
						margin-top: 14px;
						&:first-child {
							margin-top: unset;
						}
					}
				}
			}
		}
	}

	> .exercisesListWrapper {
		display: flex;
		align-items: center;

		> .exercisesList {
			margin-top: 25px;
			max-width: 450px;
			flex: 1;

			> .contentBloc {
				white-space: pre-line;
				line-height: 20px;
				word-break: break-word;
				white-space: pre-wrap;
				-moz-white-space: pre-wrap;
			}

			> .imageExercise {
				max-width: 80%;
				max-height: 45vw;
				overflow: hidden;
				display: flex;
				align-items: center;
				justify-content: center;
				border-radius: 20px;
				cursor: pointer;
				margin-left: auto;
				margin-right: auto;

				@include rwd(600) {
					max-height: 35vw;
				}

				> img {
					width: 100%;
					height: 100%;
					object-fit: cover;
				}

				.imgModal {
					max-width: 80vw;
					max-height: 80vh;
					img {
						max-width: 80vw;
						max-height: 80vh;
						display: block;
						// width:100px;
					}
				}

				@include rwd(600) {
					max-width: 360px;
					max-height: 220px;
				}
			}

			> .contentBloc {
				margin-top: 20px;
				&:first-child {
					margin-top: unset;
				}
			}
		}

		> .menu {
			margin-left: 20px;
			> button {
				> svg {
					width: 18px;
					stroke-width: 2;
				}
			}
		}
	}

	> .scores {
		> .wodScores {
			margin-top: 40px;
		}
	}
}

@import '../../../../assets/scss/styles';

#programHomePage {
    white-space:pre-wrap;

	.programPresentation {
		overflow: unset;

		.programPresentationWidgetWrapper {
			overflow: unset;
			position: relative;
			border-radius: 20px;
			padding: 16px 19px;
			background-repeat: no-repeat;
			background-size: auto 200%;
			background-position: right center;
			background-color: $secondary;
			background-image: url(../../../../assets/img/background/bolt_bluelight_small.png);

			.programIcon {
				position: absolute;
				height: 80px;
				width: 80px;
				border-radius: 20px;
				top: -35px;
				left: 38px;
				overflow: hidden;
				box-shadow: 0px 2px 15px 0px rgb(0, 0, 0, 0.2);

				img {
					width: 100%;
					height: 100%;
					object-fit: cover;
				}
			}

			.infoBar {
				display: flex;
				justify-content: flex-end;
				.rate {
					display: flex;
					align-items: center;

					background: #8bd4ff;
					color: white;
					border-radius: 2000px;
					padding: 10px;

					svg {
						stroke: white;
						stroke-width: 3;
						width: 14px;
						margin-right: 6px;
					}
				}
			}

			.texts {
				padding: 10px 19px 19px 19px;
				margin-top: 28px;
				color: white;
				p {
					margin-top: 15px;
					line-height: 22px;
					width: 84%;
					@include qhd {
						line-height: 25px;
					}
				}
			}
		}
	}
}

@import '../../assets/scss/styles.scss';

#profilePage{
	box-sizing: border-box;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	padding: 28px 26px;
		
	@include xl{justify-content: space-evenly;}

	@include xxl{
		justify-content: center;
		padding: 8px 23px;
	}

	@include rwd(1900){padding: 10px 23px;}

	> div, >button{
		max-width: 470px; 
	}

	.widgetProfileSmall{
		@include xl{
			max-width: 470px !important;
		}
	}
	
	>.column{
		display: flex;
		flex-direction: column;
		align-items: center;
		width:96%;
		margin-top:32px;

		&:first-child{margin-top:unset;}

		@include rwd(860){
			width:48%;
			margin-top:unset;
		}

		@include xxl{max-width:450px;}
		@include qhd{
			max-width:480px;
		}
	}

	>.column1{
		@include rwd(860){width:40%;}
		@include xxl{max-width:320px;}
		@include qhd{max-width:360px;}
	}

	>.column2{
		@include rwd(860){
			margin-top:0;
			margin-left:20px;
			width:55%;
			max-width:440px;
		}

		@include xxl{
			width:unset;
			flex:1;
		}

		@include rwd(1900){margin-left:40px}
		@include qhd{
			max-width:480px;
			margin-left:60px;
		}
	}

	>.column3{
		@include xxl{
			margin-top:0;
			width:unset;
			flex:1;
			margin-left:20px;
		}
		@include rwd(1900){margin-left:40px}
		@include qhd{
			max-width:480px;
			margin-left:60px;
		}
	}





	.widgetProfile, .buttonProfileWidget{
		margin-top:18px !important;

		@include rwd(1900){
			margin-top:40px !important;
		}
		
	}
	
	// .messageBox{
	// 	box-sizing: border-box;
	// 	padding:20px 10px;
	// 	border-radius: 10px;
	// 	background: $grey4;
	// 	color:$grey2;

	// 	display: flex;
	// 	justify-content: center;
	// 	align-items: center;
	// }
	
}
@import '../../../assets/scss/styles.scss';

.inputAreaChat {

	>.imgPreviewRow{
		// background:pink;
		box-sizing: border-box;
		padding:10px 20px;
		>.imagePreview{
			position:relative;
			width:80px;
			height:80px;


			>.imagePreviewWrapper{
				width:80px;
				height:80px;
				border-radius: 10px;
				overflow: hidden;
				
				>img{
					width:100%;
					height:100%;
					object-fit: cover;
				}
			}



			>button{
				position:absolute;
				top:-4px;
				right:-4px;
				>svg{
					width:13px;
					height:13px;
					stroke:$error;
					stroke-width:3;

					&:hover{
						stroke:$errorHover;
					}
				}
			}
		}
	}


	> .inputRow {
		display: flex;
		align-items: flex-end;
		box-sizing: border-box;
		padding: 10px 20px;

		> .addFiles {
			position: relative;
			> button {
				margin-bottom: 4px;
				> svg {
					stroke: $grey2;
					stroke-width: 2;
					width: 24px;
					transform: translateX(-4px);
				}
			}

			.dropdownMenuWrapper {
				> .actions {
					.imgUploadLabel {
						padding: 0;
						cursor: pointer;
						white-space: nowrap;

						display: flex;
						align-items: center;
						color: $grey1;
						transition: color 80ms ease-in-out;

						&:hover {
							color: $grey1Hover;
							svg {
								stroke: $grey1Hover;
							}
						}

						svg {
							transition: stroke 80ms ease-in-out;
							margin-right: 7px;
							width: 14px;
							stroke-width: 2;
							stroke: $grey1;
						}

						> h1 {
							// flex:1;
							// min-width: 120px;
						}

						> input {
							visibility: hidden;
							display: none;
						}
					}

					> .standard {
						color: $grey1;
						transition: color 80ms ease-in-out;

						svg {
							stroke: $grey1;
						}

						&:hover {
							color: $grey1Hover;
							svg {
								stroke: $grey1Hover;
							}
						}
					}
				}
			}
		}

		> .inputZone {
			flex: 1;

			margin-left: 8px;
			margin-right: 14px;

			textarea {
				// background:pink;
				background: white;
				width: 100%;
				border: unset;
				padding: 4px 10px 4px 10px;
				box-sizing: border-box;
				resize: none;
				min-height: 28px;
				// height:28px;
				height: 28px;
				font-size: 14px;
				transform: translateY(3px);
				max-height: 152px;
				line-height: 18px;
			}
		}

		> .sendMessage {
			margin-bottom: 6px;
			cursor: pointer;
			> svg {
				stroke: $grey2;
				stroke-width: 2;
				width: 18px;
			}
		}
	}
}

@import '../../../../../assets/scss/styles.scss';

.chronoConfigInputShow{
	color:#9BB0D2;

	@include rwd(800){
		font-size: 14px !important;
	}

	>.value{
		color:white;
		border:1px solid white;
		padding:0 36px;
		text-align: center;
		margin-top: 6px;
		border-radius: 2px;
		box-sizing: border-box;
		cursor:pointer;

		height:52px;
		display: flex;
		align-items: center;
		justify-content: center;

		@include rwd(800){
			font-size: 15px !important;
			padding:0 36px;
			height:60px;
		}
	}
}
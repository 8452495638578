@import '../../../../assets/scss/styles.scss';

.calendarWrapper{
	width:100%;

	.calendarContent{
		margin-top:28px;
		.date{
			h2{
				color:$grey2;
			}
			h1{
				margin-top:6px;
				color:$grey1;
			}
		}

		.sessionList{
			margin-top:20px;

			.sessionLine{
				margin-top:10px;

				&:first-child{
					margin-top: unset;
				}
			}
		}


	}

	.emptyMessage{
		margin-top:20px;
	} 
}
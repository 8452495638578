@import '../../assets/scss/styles.scss';


.registerForm{
	.formContainer{
		.textInput{
			margin-top:20px !important;
		}

		.formCheckbox{
			display: flex;
			align-items: center;
			color:$grey2;

			input[type="checkbox"]{
				border:1px solid $grey2;
				padding:10px !important;

			}

			label{
				margin-left:8px;
				padding-top:1px;

				span{
					color:$secondary;
					text-decoration: underline;

					&:hover{
						color:#06a3fd;
					}
				}
			}



		}

		.cguError{
			color:$error !important;
			text-decoration: underline;
			span{
				color:$error !important;
			}
			input[type="checkbox"]{
				outline:1px solid $error !important;
				border:unset;
				padding:20px;

			}
		}


		.actions{

			width:100%;

			.formValidationFrontPage{
				display:flex;
				justify-content: space-between;
				width: 100%;
			}
		}
	}

}

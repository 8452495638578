@import '../../assets/scss/styles.scss';

#settingsPage{
	box-sizing: border-box;
	padding: 28px 26px;
	position:relative;
	min-height: 400px;
	padding-bottom:60px;

	@include rwd(800){
		display:flex;
		flex-direction: row;
		width:100%;

		

	}

	.pageContainerSettings{
		>h1{
			color: $grey1;
			margin-top: 17px;
		}

		>.settingsWrappers{
			margin-top: 60px;

			max-width: 380px;
			margin-left: auto;
			margin-right: auto;

			@include rwd(700){
				max-width: 450px;
			}

			@include rwd(800){
				margin-left: unset;
				margin-right: unset;
			}
		}
	}

	.selected{

	}

	.menuContainer{
		
	}


	.settingsPageContent{
		margin-top:62px;
	}
}
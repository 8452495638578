@import '../../../assets/scss/styles.scss';

#sidebar {
	position: fixed;
	z-index:10001;
	left: -245px;
	top: 0px;
	bottom: 0px;
	width: $sidebarSize1;
	background: white;
	box-shadow: 2px 0px 15px rgba(0, 0, 0, 0.07);
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;

	padding: 0px 20px 30px 20px;
	box-sizing: border-box;
	z-index: 200;

	transition: left 120ms ease-in-out, box-shadow 120ms ease-in-out;

	overflow-y: auto;

	@include rwd(500){
		width: $sidebarSize2;
	}

	@include xl{
		width:$sidebarSize3;
		left:0px;
		box-shadow:unset;
	}

	@include xxl{
		width:$sidebarSize4;
		// background:orange;
	}

	.top {
		display: flex;
		flex-direction: column;
		align-items: center;
		width:100%;

		.modalMenu {
			// background:red;
			margin-top: 16px;
			align-self: flex-start;

			svg {
				stroke: $grey1;
				stroke-width: 2;
				width: 17px;
				margin-left: 6px;
			}
		}

		.userCard{
			margin-top:34px;
			width:80%;
		}

		.menu {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: flex-start;
			margin-top: 20px;

			@include rwd(800){
				margin-top: 30px;
			}
		}
	}

	.bottom {
	}

	hr {
		border: none;
		border-top: 1px solid $grey4;
		background: unset;
		box-sizing: border-box;
		width: 157px;

		margin-top: 15px;
	}
}

@import '../../assets/scss/styles.scss';

#calendarPage {
	.calendarApp {
		min-height: calc(100vh - 46px);

		@include rwd(800) {
			min-height: calc(100vh - 54px);
		}
	}

	.calendarNoData {
		display: flex;
		flex-direction: column;
		
		align-items: center;
		justify-content: center;
		min-height: calc(100vh - 46px);

		@include rwd(800) {
			min-height: calc(100vh - 54px);
		}

		.calendarNoDataWrapper {
			display: flex;
			flex-direction: column;
			align-items: center;
			max-width: 360px;
			box-sizing: border-box;
			padding: 25px;
			text-align: center;
			padding-bottom: 60px;
			@include rwd(680) {
				max-width: 480px;
			}
			@include rwd(1550) {
				max-width: 540px;
				padding-bottom: 120px;
			}

			> img {
				width: 70%;
				max-width: 250px;
				@include rwd(680) {
					max-width: 280px;
				}
				@include rwd(1550) {
					max-width: 310px;
				}
			}

			> h1 {
				color: $grey1;
				margin-top: 35px;
				@include rwd(680) {
					font-size: 20px;
					margin-top: 45px;
				}
				@include rwd(1550) {
					font-size: 22px;
					margin-top: 55px;
				}
				@include qhd {
					font-size: 24px !important;
				}
			}

			> p {
				color: $grey2;
				line-height: 18px;
				margin-top: 15px;
				@include rwd(680) {
					margin-top: 20px;
					font-size: 15px;
					line-height: 21px;
				}

				@include rwd(1550) {
					font-size: 16px;
					margin-top: 24px;
					line-height: 23px;
				}
				@include qhd {
					font-size: 16px !important;
				}
			}

			> .actions {
				margin-top: 40px;
				display: flex;
				flex-direction: column;
				align-items: center;
				@include rwd(680) {
					margin-top: 50px;
				}
				@include rwd(1550) {
					margin-top: 55px;
				}

				> button {
					&:nth-child(2) {
						margin-top: 10px;
					}

					@include rwd(680) {
						font-size: 14px;
					}
					@include rwd(1550) {
						font-size: 16px !important;
					}
					@include qhd {
						font-size: 17px !important;
					}
				}
			}
		}
	}
}

@import '../../../../assets/scss/styles.scss';

.wodbookShow{
	position:fixed;
	top:46px;
	right:0;
	bottom:0;
	width:100vw;
	overflow: auto;
	background:white;
	transition: right 140ms ease-in-out;
	z-index: 20;

	@include rwd(800){
		top:54px;
	}

	@include rwd(800){
		position: unset;
		top:unset;
		right:unset;
		bottom:unset;
		width:unset;
		overflow: unset;
		transition:unset;
	}


	>.closeButton{
		position:fixed;
		top:78px;
		right:24px;
		z-index: 200;

		>svg{
			width:28px;
			stroke-width: 2;
			stroke:$grey1;
		}

		&:hover{
			>svg{
				stroke:$grey2;
			}
		}
	}
}


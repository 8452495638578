@import '../../assets/scss/styles.scss';

.socialButtons{
	.separation {
		display: flex;
		align-items: center;
		justify-content: space-around;
		margin-top: 34px;
	
		@media screen and (min-height: 950px) {
			margin-top: 50px;
		}
	
		.lineGrey {
			height: 1px;
			background: $grey2;
			flex: 1;
		}
	
		.text {
			color: $grey2;
			margin-left: 20px;
			margin-right: 20px;
		}
	}
	
	.socialConnexion {
		margin-top: 20px;
		display: flex;
		flex-direction: column;
		align-items: center;
	
		@media screen and (min-height: 950px) {
			margin-top: 35px;
		}
	
		.socialButton {
			display: flex;
			justify-content: space-between;
			align-items: center;
			border: 1.5px solid $grey3;
			height: 50px;
			border-radius: 5px;
			max-width: 100%;
			width: 310px;
			box-sizing: border-box;
			margin-top: 11px;
			@media screen and (min-height: 950px) {
				margin-top: 14px;
			}
	
			@include qhd {
				width: 400px;
				height: 58px;
			}
	
			p {
				color: $grey1;
				padding: 0 22px;
			}
			.logo {
				height: 100%;
				display: flex;
				align-items: center;
				justify-content: center;
				border-left: 1.5px solid $grey3;
				padding: 0 16px;
				box-sizing: border-box;
				svg {
					width: 20px;
				}
			}
	
			&:hover {
				background: $grey4;
			}
		}
	}
}


@import '../../../../assets/scss/styles.scss';

.modalSessionAdd{
	padding:26px 38px;
	min-width: none;
	>h1{
		color:$grey1;
	}

	>form{
		margin-top: 40px;

		>.textInput{
			@include rwd(350){min-width:280px;}
			@include rwd(400){min-width:320px;}
			@include rwd(550){min-width:380px;}

			margin-top: 15px;
			
		}
	}

	>.actions{
		display: flex;
		justify-content: space-between;
		margin-top: 40px;

		>.btn{
			padding:0;
		}

	}
}
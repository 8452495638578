@import '../../../../assets/scss/styles.scss';

.calendarSelector {
	> .calendarSelectorTable {
		width: 100%;
		border-collapse: collapse;
		border-spacing: 0;
		table-layout: fixed;

		> thead {
			> .titles {
				> .titleColumn {
					text-transform: uppercase;
					color: $grey1;
				}
			}
		}

		> tbody {
			> .separator {
				height: 16px;
			}

			> .weekRow {
				padding-top: 16px;

				> td {
					cursor: pointer;
					text-align: center;
					box-sizing: border-box;
					padding: 7px 8px;
					height: 26px;
					position: relative;

					> .dots {
						display: flex;
						justify-content: center;
						position: absolute;
						width: 100%;
						top: 22px;
						left: 50%;
						transform: translateX(-50%);
						// gap: 2px;

						> .dot {
							width: 4px;
							height: 4px;
							border-radius: 1000px;
						}
						// > .dotBlue {
						// 	background: $secondary;
						// }
						> .dotGreen {
							background: $primary;
						}
					}
				}
			}
		}
	}
}

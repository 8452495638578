@import '../../../../../../assets/scss/styles.scss';

.blockEdit{

	display: flex;
	// align-items: flex-start;
	gap:10px;

	>.positionEdit{
		display: flex;
		flex-direction: column;
		justify-content: center;
		gap:6px;

		>button{
			>svg{
				width:14px;
				stroke-width: 2;
				stroke:$grey1;
				cursor:pointer;
			}
		}

	}

	>.content{
		flex:1;
		// background:green;

		>.contentTextContainer{
			>textarea{
				display: block;
				margin:0;
				width:100%;
				// min-height: 100%;
				box-sizing: border-box;
				background:$grey4;
				border:1px solid $grey3;
				padding:10px 13px;
				resize: none;

				appearance: none;
				-moz-appearance: none;
				-webkit-appearance: none;

				&:focus{
					border:1px solid $grey1;
				}
			}
		}

		>.contentImageContainer{
			width:100%;
			max-width: 380px;
			height:170px;
			border-radius: 20px;
			overflow: hidden;

			@include rwd(440){height:230px;}
			@include rwd(470){height:250px;}
			@include rwd(800){height:200px;}
			@include lg{height:240px;}


			>img{
				width:100%;
				height:100%;
				object-fit: cover;
			}
		}
		// vertical-align: baseline;
	}

	>.actions{
		// background:orange;
		display: flex;
		flex-direction: column;
		gap:10px;
        justify-content: center;

		>button, .imgUploadLabel{
			width:18px;
			height:18px;
			border-radius: 2px;
			display: flex;
			align-items: center;
			justify-content: center;

			>svg{
				width:70%;
				stroke:$grey1;
				stroke-width:2;
			}

			&:hover{
				// background:$grey2;
				>svg{
					stroke:$grey1Hover;
				}
			}
		}

		.imgUploadLabel{
			padding:0;
			display: flex;
			cursor:pointer;

			>input{
				visibility: hidden;
				display: none;
			}
		}
	}
}

@import '../../../../assets/scss/styles.scss';

.chronoConfigFormContainer{
	margin-top: 20px;
	flex:1;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	box-sizing: border-box;
	padding-bottom: 120px;
	padding-left: 26px;
	padding-right: 26px;
	padding-top:20px;

	max-width: 400px;
	width:100%;
	margin-left: auto;
	margin-right: auto;

	@include rwd(800){
		max-width: 500px;
		justify-content: center;

		padding-bottom: 100px;
	}
}
@import '../../assets/scss/styles.scss';

#programPage{
	// background:red;
	box-sizing: border-box;
	padding:28px 28px 40px 28px;

	@include qhd{
		padding:40px 28px 40px 28px;
	}

	.programPageWrapper{
		margin-left:auto;
		margin-right:auto;
		max-width: 1500px;
	}

	.pageTitle{
		margin-top : 18px;
		color:$grey1;
	}



	.programColumnsWrapper{
		display: flex;
		flex-direction: column;
		align-items: center;

		@include rwd(800){
			flex-direction: row;
			flex-wrap: wrap;
			align-items: unset;
			justify-content: center;
			margin-top:34px;
		}

		@include xxl{
			justify-content: space-between;
		}

		@include qhd{
			margin-top:45px;
		}

		.programsColumnsContainer{
			// background:green;
			margin-top:30px;
			width:100%;
			max-width: 480px;

			@include rwd(800){
				width:47%;
				margin-top:0 !important;
			}
			@include xxl{
				width:31%;
			}
		}
	
		.programsColumnsContainer1{
			margin-top:50px;
		}

		.programsColumnsContainer2{
			margin-top:50px;
			@include rwd(800){
				margin-left:6%;
			}
			@include xxl{
				margin-left:0%;
			}
		}
	
		.programsColumnsContainer3{
			margin-top:50px;

			@include xxl{
				margin-top: 0;
			}
		}
	

		.programsPending{
			@include rwd(800){
				// margin-top:50px;
			}
			@include xxl{
				margin-top:0px;
			}
		}
	
	
		.widgetProfile{
			border-radius: 0;
			padding:10px 0 0 0;
			overflow: unset;
		}
	
		.widgetBackgroundGrey{
			border-radius: 20px;
			background:$grey4;
			padding:25px 27px 20px 27px;
		}


	}



}



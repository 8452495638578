@import '../../../assets/scss/styles.scss';

.navbarSearch {
	position: relative;
	display: flex;
	align-items: center;
	width: 16px;
	height: 28px;
	padding: unset;
	overflow: hidden;

	transition: width 90ms ease-in-out;

	@include rwd(800) {
		width: 20px;
		height: 34px;
	}

	input {
		z-index: 5;
		position: absolute;
		box-sizing: border-box;
		left: 0;
		top: 0;
		width:100%;
		height: 28px;
		padding:10px 10px 10px 36px;

		border-radius: 5px;
		border: unset;

		background: #fff;
		color:$grey1;
		opacity:0;
		cursor:pointer;

		transition: width 300ms ease-in-out;

		&::placeholder {
			font-size: 12px;
			font-weight: 500;
			color: $grey2;
			margin-bottom:3px;
			padding:1px 0;

			@include rwd(800){font-size: 14px;}
		}

		@include rwd(800){
			height: 34px;
			font-size: 14px;
		}
	}

	.searchButton {
		width: 16px;
		z-index: 4;
		margin: 0;
		cursor:pointer;

		@include rwd(800) {width: 20px;}
	}
}


.navbarSearchOptionsWrapper{
	margin-left:12px;
}
@import '../../../../assets/scss/styles.scss';

.planningButton{
	margin-top: 15px;
	padding:0px 6px;
	// background:red;
	@include rwd(800){
		margin-top: 0;
	}
}

.calendarSessionNavbar{
	// background:chartreuse;
	padding:21px 28px;

	@include rwd(800){
		display: flex;
		gap:20px;
	}

	>.left{
		display: flex;
		justify-content: space-between;


		@include rwd(800){
			flex:1;
		}

		>.left{

			>.titles{
				>h2{
					color:$grey2;
					margin-top: 5px;

					&:first-child{
						margin-top: 0px;
					}
				}

				>h1{
					display: block;
					color:$grey1;
					margin-top: 7px;
					display: flex;
					align-items: center;
					width:100%;
					// background:purple;

					>.sessionName{
						// flex:1;
						// background:green;

						>form{
							>input{
								border: 1px solid $grey2;
								padding:5px 6px 4px 6px;
								border-radius: 4px;
							}
						}


					}

					>.actionsSession{
						display: inline-flex;
						align-items: center;
						// background:red;
						margin-left: 17px;
						>button{
							margin-left:14px;

							&:first-child{
								margin-left: 0px;
							}
							// background:orange;
							>svg{
								stroke:$warning;
								width:14px;
								stroke-width: 3;

								@include qhd{
									width:17px;
								}
							}

							&:hover{
								>svg{
									stroke:$warningHover;
								}
							}
						}

						>.buttonCheck{
							svg{
								stroke:$primary;
								width:16px;

								&:hover{
									stroke:$primaryHover;
								}
							}
						}

                        >.buttonCancel{
                          svg{
                            stroke:$error;
                            width:16px;

                            &:hover{
                              stroke:$errorHover;
                            }
                          }
                        }
					}



				}
			}

			>.menu{
				// background:purple;
				display: flex;
				gap:15px;
				margin-top: 14px;
				color:$grey1;
				cursor:pointer;
				>.menuItem{
					padding-bottom:3px;
				}
			}

		}

		>.right{
			display: flex;
			flex-direction: column;
			justify-content: flex-start;
			// background:red;
			// background:green;

			@include rwd(800){
				margin-right: 30px;
			}
			@include rwd(1600){
				margin-right: 40px;
			}

			.editsBtn{
				>svg{
					width:18px;

					stroke-width:2;
				}


			}

			.deleteButton{
				>svg{stroke:$error;}
				&:hover{
					>svg{
						stroke:$errorHover;
					}
				}
			}

			.editButton{
				>svg{stroke:$warning;}
				&:hover{
					>svg{
						stroke:$warningHover;
					}
				}
			}

			.deleteModale{
				padding: 28px 38px;
				width:100%;
				max-width: 400px;
				box-sizing: border-box;
				color:$grey1;

				>h2{
					margin-top: 20px;
					color:$grey2;
					line-height: 19px;
				}


				>.actions{
					display: flex;
					align-items: center;
					justify-content: space-between;
					margin-top: 28px;

					>.btn{
						padding:0;
					}
				}
			}
		}
	}

	>.right{
		display:none;
		color:$grey1;

		@include rwd(800){
			flex:1;
			display:flex;
			align-items: flex-end;

		}

		@include rwd(1600){padding-left:20px;}
		@include qhd{padding-left:30px;}

	}



}

@import '../../assets/scss/styles.scss';

.cguPage {
  padding: 2% 15% 2% 15%;
}

.cgu{
    white-space: pre-line;
    color: $grey1;
    img {
        display: flex;
        margin-left: auto;
        margin-right: auto;

    }
    h2{
        margin-top: 3%;
        margin-bottom: 3%;
        color:$primary;
        font-weight: bolder;
        font-size: 28px;
    }
    h3{
        text-decoration: underline;
        margin-top: 2%;
        margin-bottom: 2%;
        font-size: 24px;
    }
    h4{
        margin-top: 2%;
        margin-bottom: 2%;
        font-size: 16px;
        color: $grey1;
        font-weight: 550;
    }
    p{
        font-size: 15px;
        line-height: 1.6em;
    }
}

@import '../../../../assets/scss/styles.scss';

.timerPreStart{
	display:flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	position:absolute;
	top:0;
	bottom:0;
	width:100%;
	// background:pink;

	>h1{
		color:white;
		font-size:40px;

		@media all and (max-height:400px){
			font-size:32px;
		}
	}

	>button{
		background:$primary;
		width:160px;
		height:160px;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 10000px;
		margin-top: 70px;
		box-shadow: 0px 5px 12px rgba(0,0,0,0.15);

		@media all and (max-height:400px){
			width:100px;
			height:100px;
			margin-top: 20px;
		}
		@media all and (min-height:401px) and (max-height:500px) {
			width:140px;
			height:140px;
			margin-top: 40px;
		}

		>.circle{
			border-radius: 10000px;
			padding:20px 15px 20px 25px;
			border:6px solid white;

			@media all and (max-height:400px){
				border:px solid white;
			}

			>svg{
				width:70px;
				stroke:white;
				stroke-width:2;
				// padding-left: 20px;

				@media all and (max-height:400px){
					width:30px;

				}
				@media all and (min-height:401px) and (max-height:500px) {
					width:60px;
				}
			}
		}

		&:hover{
			background:$primaryHover;
		}
	}

	>.details{
		margin-top: 70px;
		min-height: 40px;

		@media all and (max-height:400px){
			margin-top: 20px;
		}

		@media all and (max-height:500px) and (min-height:401px){
			margin-top: 40px;
		}

		>.phrase{
			color:white;
			text-align: center;
			margin-top: 5px;
		}
	}

}
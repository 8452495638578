@import '../assets/scss/styles.scss';

#loginLayout {
	.loginLayoutWrapper {
		width: 100%;
		display: flex;
		flex-direction: column;
		height: 100%;

		@include xl {
			flex-direction: row;
			padding: 0px 0px;
			width: 55%;
		}

		.leftLoginWrapper {
			// background:orange;
			flex: 1;
			position: relative;
			box-sizing: border-box;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;

			padding: 60px 18px;

			@include rwd(340) {
				padding: 60px 24px;
			}

			@include xl {
				max-width: unset;
				width: 45%;
			}
		}

		.rightLoginWrapper {
			position: fixed;
			top: 0;
			bottom: 0;
			right: 0;
			left: 55%;
		}
	}







	.loginLeftContainer{
		box-sizing: border-box;
		width:100%;
		max-width: 400px;
		
	
		@include xl {
		}
		@include qhd {
			max-width: 500px;
		}


		.headerHustleUp{
			img {
				height: 21px;
				@include qhd {height: 26px;}
			}

			h1 {
				color: $grey1;
				margin-top: 12px;
				@media screen and (min-height: 950px) {margin-top: 16px;}
			}

			p {
				color: $grey2;
				margin-top: 14px;
				line-height: 20px;
				@media screen and (min-height: 950px) {margin-top: 19px;}
				@include qhd {line-height: 24px;}
			}
		}


		.headerRoundIcon{
			display: flex;
			flex-direction: column;
			align-items: center;
			h1 {
				color: $grey1;
				margin-top: 22px;
				line-height: 30px;
				text-align: center;
				@media screen and (min-height: 950px) {
					margin-top: 30px;
				}
			}

			p {
				color: $grey2;
				margin-top: 18px;
				line-height: 18px;
				text-align: center;
				@media screen and (min-height: 950px) {
					margin-top: 25px;
				}
			}
		}

		.formContainer{
			margin-top: 45px;

			@media screen and (min-height: 950px) {
				margin-top: 55px;
			}

			.textInput {
				margin-top: 6px;
				&:first-child{margin-top:unset;}
			}

			.formValidationFrontPage{
				margin-top: 30px;
				display: flex;
				justify-content: space-between;
				align-items: center;
				@media screen and (min-height: 950px) {margin-top: 38px;}

				.left {
					.btn {
						padding: 12px 24px;
					}
				}

				.right {
					display: flex;
					flex-direction: column;
					align-items: flex-end;
					> p {color: $grey2;}
					a {
						display: flex;
						align-items: center;
						margin-top: 3px;
						svg {
							margin-left: 6px;
							width: 16px;
							stroke: $grey1;
							stroke-width: 2;
						}
						&:hover {
							color: $primary;
							svg {stroke: $primary;}
						}
					}
				}
			}
		}

		.formValidationClassic{
			display: flex;
			justify-content: center;
			margin-top: 35px;

			@media screen and (min-height: 950px) {
				margin-top: 45px;
			}
		}


		.backButton {
			color: $grey2;
			border-bottom: 1px solid $grey2;
			position: absolute;
			bottom: 45px;
			left:50%;
			transform:translateX(-50%);

			@media screen and (min-height: 950px) {bottom: 60px;}
			@include qhd {bottom: 120px;}

			&:hover {
				color: $grey1;
				border-bottom: 1px solid $grey1;
			}
		}
	}


}

@import '../../../../assets/scss/styles.scss';

.timerConfigNavbar{
	color:white;
	margin-top: 30px;

	@include rwd(400){
		margin-top: 40px;
	}

	@include rwd(800){
		margin-top: 50px;	
	}

	>.menuActivities{
		display: flex;
		justify-content: center;

		> button{
			margin-left: 8px;

			@include rwd(348){
				margin-left: 14px;
			}

			@include rwd(800){
				font-size: 18px;	
			}

			&:first-child{
				margin-left: unset;
			}
		}

	}

	>.description{
		margin-top: 20px;
		width:90%;
		margin-left: auto;
		margin-right: auto;
		text-align: center;
		line-height: 16px;
		max-width: 500px;



		@include rwd(800){
			font-size: 14px;	
			line-height: 24px;
		}
	}
}
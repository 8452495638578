@import '../../../../assets/scss/styles';


	.programHomePageNavbar{
		margin-top:10px;
		display: flex;
		justify-content: space-between;
		align-items: center;

		// background:green;
		position:relative;

		.left{
			display: flex;
			align-items: center;
			// background:red;
			h1{
				padding-bottom:6px;
				padding-top:6px;
				color:$grey1;

			}

			.titleActive{
				padding-bottom:4px;
				border-bottom: 2px solid $grey1;
				padding-top:6px;
			}
		}

		.right{
			display: flex;
			align-items: center;
			// background:orange;
            .suscription{
                display: flex;
                flex-direction: row;
                align-items: center;
                p{
                  text-align: center;
                  color: $grey2;
                }
                .suscription--btn{
                  display: flex;
                  flex-direction: row;
                  align-items: center;
                  background-color: $warning;
                  padding-top: 3px;
                  padding-bottom: 3px;
                  padding-left: 10px;
                  padding-right: 10px;
                  border-radius: 25px;
                  margin-left: 10px;
                  p{
                    text-align: center;
                    color: $grey1;
                  }
                  svg{
                    width:12px;
                    stroke: $grey1;
                    stroke-width:3;
                    margin-left:5px;
                  }
                }
            }


		}


		.programNavbarMenuDesktop{
			margin-left:22px;

			a{
				margin-left:20px;
				color:$grey1;
			}

			.activeLink{
				padding-bottom:1px;
				border-bottom: 2px solid $grey1;
				padding-top:3px;
			}


			.logoutLink{
				display: flex;
				align-items: center;
				color:$error !important;

				svg{
					width:12px;
					stroke:$error;
					stroke-width:3;
					margin-right:10px;
				}
			}
		}



		.exitProgramConfirmation{
			padding:28px 37px;
			width:95vw;
			box-sizing: border-box;
			max-width:420px;

			h1{
				line-height: 26px;
				color:$grey1;
			}

			p{
				margin-top:14px;
				line-height: 22px;
				color:$grey2;
				@include qhd{
					line-height: 25px;
				}
			}

			>.actions{
				width:100%;
				margin-top:18px;
				display: flex;
				justify-content: space-between;
				align-items: center;

				button{
					color:$grey2;
					&:nth-child(2){
						color:$error;
					}
				}
			}

		}

	}



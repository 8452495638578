@import '../../../assets/scss/styles.scss';

.calendarMainSidebar {
	background: #fbfcff;
	position: fixed;
	bottom: 0;
	left: 0;
	top: 46px;
	width: 270px;
	z-index: 35;
	box-shadow: 5px 0 15px rgba(0, 0, 0, 0.1);
	transition: left 200ms ease-out;
	padding: 27px 22px;
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	justify-content: space-between;

	@include rwd(800) {
		top: 54px;
	}

	@include xl {
		left: $sidebarSize3;
	}

	@include xxl {
		left: $sidebarSize4;
	}

	@include rwd(1600) {
		top: 144px;
		box-shadow: unset;
		z-index: 20;
	}

	> .top {
		> .calendarMainSidebarFilters {
			margin-top: 0;

			@include rwd(800) {
				margin-top: 50px;
			}
		}
	}

	> .bottom {
		margin-bottom: 20px;
		> .calendarHideMainSidebar {
			display: flex;
			align-items: center;
			color: $grey2;
			cursor: pointer;

			> svg {
				width: 18px;
				stroke: $grey2;
				stroke-width: 2;
				transform: rotate(180deg);
				margin-right: 10px;
			}
		}
	}
}

.calendarMainSidebarOut {
	left: -270px;
}

.calendarMainSidebarButtonVisible {
	background: $secondary;
	padding: 9px;
	border-radius: 24px;
	position: fixed;
	bottom: 40px;
	left: 20px;
	z-index: 35;
	box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.1);
	cursor: pointer;
	transition: background 120ms ease-in-out;

	> svg {
		stroke: white;
		stroke-width: 2;
		width: 20px;
	}

	&:hover {
		background: $secondaryHover;
	}

	@include rwd(800) {
		padding: 14px;
		> svg {
			width: 20px;
		}
	}

	@include xl {
		left: $sidebarSize3 + 20px;
	}

	@include xxl {
		left: $sidebarSize4 + 20px;
	}
}

.calendarMainSidebarButtonVisibleHidden {
	display: none;
}

@import '../../../../assets/scss/styles.scss';

.AddPersonnalRecordModal {
	padding: 26px 38px;
	min-width: none;
	color: $grey1;
	width: 90vw;
	box-sizing: border-box;
	max-width: 500px !important;



	> form {
		margin-top: 40px;

		> .recordCategorySelector{
			z-index:9000;

			>.recordCategorySelectorWrapper{
				border-radius: 4px;

				>.defaultSelect{
					border-radius: 4px;

					// >.option{color:$grey2 !important;}
				}
			}
		}




		> .textInput {
			margin-top: 16px;
		}

		>.inputs{
			margin-top: 31px;

			>.inputsTime{
				display: flex;
				gap:20px;
				max-width: 300px;
			}
		}



		// > .timeInputs {
		// 	display: flex;

		// 	> .textInput {
		// 		margin-top: 16px;

		// 		&:nth-child(2) {
		// 			margin-left: 16px;
		// 		}
		// 	}
		// }
		> .actions {
			display: flex;
			justify-content: flex-end;
			align-items: center;
		}
	}
}

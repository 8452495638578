@import '../../../../../../assets/scss/styles.scss';

.imageExercise{
	max-width:80%;
	max-height: 35vw;
	overflow: hidden;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 20px;
	cursor: pointer;
	margin-left: auto;
	margin-right: auto;

	// background:red;

	>img{
		width:100%;
		height:100%;
		object-fit: cover;
	}

	.imgModal{
		max-width:80vw;
		max-height: 80vh;
		img{
			max-width:80vw;
			max-height: 80vh;
			display: block;
			// width:100px;
		}
	}

	@include rwd(600){
		max-width: 360px;
		max-height: 220px;
	}
}
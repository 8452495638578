@import '../../../../../assets/scss/styles.scss';

.timerButtonAddStep{
	display: flex;
	align-items: center;
	gap:10px;
	color:white;
	margin-left: auto;
	margin-right: auto;
	margin-top: 20px;
	>svg{
		
		width:14px;
		stroke:white;
		stroke-width:3;
	}
}
@import '../../assets/scss/styles.scss';


#homePage{
    white-space:pre-wrap;
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding: 28px 26px;

	// background:red;

	@include rwd(860){
		flex-direction: row;
		justify-content: center;
		align-items: flex-start;
		padding-top:40px;
		padding-bottom:60px;
	}

	>.column{
		display: flex;
		flex-direction: column;
		align-items: center;
		width:100%;
		max-width: 540px;

		margin-left:auto;
		margin-right:auto;
		// background:orange;


		&:first-child{margin-top:unset;}

		@include rwd(860){
			margin-left:unset;
			margin-right:unset;
		}

	}

	>.column1{
		margin-top:12px;
		// background:blue;
		@include rwd(860){
			margin-top:0px;
			max-width: 480px;
		}

		@include xxl{
			margin-top:0px;
			max-width: 610px;
		}

		@include rwd(1500){
			padding-right:60px;
			margin-right:60px;
			border-right:1px solid $grey4;
		}

		@include rwd(1700){
			max-width: 730px;
		}

		@include qhd{
			max-width: 830px;
			padding-right:80px;
			margin-right:80px;
		}


	}

	>.column2{
		@include rwd(860){
			margin-left:28px;
			max-width: 340px;
		}

		@include xxl{
			margin-left:50px;
			max-width: 350px;
		}

		@include rwd(1500){
			margin-left:unset;
		}

		@include rwd(1700){
			max-width: 400px;
		}

		@include qhd{
			max-width: 450px;
		}
	}

	.calendarWrapper{
		margin-top:29px;

		@include rwd(860){
			margin-top:0px;
		}
	}

	hr{
		width:100%;
		border: 1.5px solid $grey4;
		margin-top:40px;
	}

	.newsWrapper{
		margin-top:60px;

		@include qhd{margin-top:90px;}
	}


	// .welcomeWrapper{

	// 	h1{

	// 	}

	// 	.welcomeWidgetWrapper{

	// 	}

	// }
}

@import '../../assets/scss/styles.scss';

.errorModal {
  padding:26px 38px;
  min-width: none;
  >h1{
    color:$grey1;
  }

  >.body{
    margin-top: 20px;
  }

  >.actions{
    display: flex;
    justify-content: space-between;
    margin-top: 25px;

    >.btn{
      padding:0;
    }

  }
}

@import '../../../assets/scss/styles.scss';

#programMemberPage {
	box-sizing: border-box;
	padding: 28px 26px;

	@include rwd(800) {
		padding-top: 40px;
		padding-bottom: 60px;
	}

	> .programMemberPageWrapper {
		display: flex;
		flex-direction: column;
		align-items: center;
		width: 100%;
		margin-top: 50px;

		@include xl {
			margin-top: 60px;
		}
		@include qhd {
			margin-top: 70px;
		}

		@include rwd(800) {
			flex-direction: row;
			align-items: flex-start;
			justify-content: center;
		}

		> .column {
			width: 100%;
			max-width: 500px;
		}
		> .column1 {
			margin-top: 10px;
		}
		> .column2 {
			@include rwd(800) {
				margin-left: 40px;
				margin-top: 10px;
			}

			@include lg {
				margin-left: 70px;
			}
			@include xl {
				margin-left: 100px;
			}
			@include qhd {
				margin-left: 140px;
			}
		}

		.progListMember {
			margin-top: 60px;
		}

		.progAcceptNewMember {
			margin-top: 60px;
			@include rwd(800) {
				margin-top: 0px !important;
			}
		}
	}
}

@import '../../../assets/scss/styles.scss';

.navbarSearchOptions{
	display: flex;
	align-items: center;

	button{


		padding:0px 12px;
		color:$grey2;
		background:$grey4;

		height:28px;
		box-sizing: border-box;
		border: 1px solid $grey3;

		transition: background 100ms ease-in-out;

		&:first-child{
			// background:green;
			border-radius: 5px 0px 0px 5px;
		}

		&:nth-child(2){
			// background:red;
			border-radius: 0px 5px 5px 0px;
		}

		@include xl{
			height: 34px;
		}

	}

	.active{
		color:$grey1;
		background:white;
	}
}
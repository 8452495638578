@import '../../../../assets/scss/styles.scss';



.newsCard{
	width: 100%;
	cursor:pointer;
	white-space:pre-wrap;
	.photo{
		width: 100%;
		height:135px;
		border-radius: 20px;
		overflow: hidden;

		@include rwd(420){
			height:170px;
		}

		@include rwd(520){
			height:150px;
		}

		@include xxl{
			height:165px;
		}

		@include rwd(1700){
			height:190px;
		}

		@include qhd{
			height:220px;
		}


		img{
			width:100%;
			height:100%;
			object-fit: cover;
		}
	}

	h1{
		color:$grey1;
		margin-top:18px;
		line-height: 20px;
	}

	p{
		display: flex;
		align-items: center;
		color:$grey2;
		margin-top:14px;
		svg{
			margin-left:5px;
			width:10px;

			stroke-linecap: round;
			stroke-linejoin: round;
			fill:none;

			stroke-width: 3;
			stroke: $grey2;
		}

		&:hover{
			color:$grey1;

			svg{
				stroke: $grey1;
			}
		}
	}







	}



	.newsModalContent{


		width: 100%;
		background:white;
		// border-radius: 10px;
		overflow: hidden;
		display: flex;
		flex-direction: column;
		overflow: auto;
		max-height: 920px;
		max-width: 500px;

		box-sizing: border-box;

		@include xl{
			height:90%;
			max-height: 1200px;
			// max-width: 500px;
			// margin-top:10%;

		}

		@include qhd{max-width: 580px;}

		.illu{
			// width: 200px;
			height:180px;
			overflow: hidden;

			@include rwd(450){
				height:240px;
			}

			@include xl{
				height:280px;
			}
			img{
				width:100%;
				height:100%;
				object-fit: cover;
			}
		}

		.contentNewsModal{

			.texts{
				box-sizing: border-box;
				padding:20px;

				@include rwd(450){
					padding:40px;
				}

				h1{
					color:$grey1;
					line-height:24px;
				}
				.paragraphe{
					margin-top:20px;
					line-height: 18px;

				}
			}
			// flex:1;


		}
	}

	>.actions{
		height: 14%;
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		// background:rgb(47, 189, 47);
	}

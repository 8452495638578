@import '../../../assets/scss/styles.scss';

.sidebarButton {
	display: flex;
	align-items: center;
	box-sizing: border-box;
	width: 157px;
	border-radius: 10px;
	padding: 10px 14px;
	margin-top: 9px;

	transition: background-color 70ms ease-in;

	&:first-child {
		margin-top: unset;
	}

	@include rwd(500){
		width: 175px;
	}

	@include xxl{
		width: 210px;
		margin-top: 11px;
		padding: 11px 14px;
	}


	svg {
		stroke: $grey2;
		width: 12px;
		min-width: 12px;
		stroke-width:3;
		stroke-linecap: round;
		stroke-linejoin: round;
		fill:none;

		transition: stroke 70ms ease-in;

		@include rwd(500){width: 14px;}
		@include qhd{width: 16px; padding-bottom:1px;}
	}

	.text {
		margin-left: 10px;
		color: $grey2;

		transition: color 70ms ease-in;

		@include rwd(1450){
			margin-left: 12px;
		}
	}

  >.infos{
    width:55px;
    margin-left:10px;
    // background:purple;
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    @include rwd(500){
      margin-left:18px;
    }

    @include rwd(1750){margin-left:22px;}
    @include qhd{margin-left:28px;}
    // @include xl{margin-left:10px;}

    >.notificationNbWrapper{
      height:18px;
      min-width: 18px;

      >.notificationNb{
        background:$primary;
        color:white;
        border-radius: 10000px;
        padding:0 6px;
        padding-top:1px;
        box-sizing: border-box;
        font-size:9px;
        height:100%;
        display: flex;
        align-items: center;
        justify-content: center;

        @include qhd{
          font-size: 10px;
        }
      }
    }

  }
}

.sidebarButtonActive {
	background: $grey4;

	svg {
		stroke: $grey1;
	}

	.text {
		color: $grey1;
	}
}

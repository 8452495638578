@import '../../../assets/scss/styles.scss';

.chatMainSidebar{
	position: fixed;
	top:46px;
	left:0;
	bottom:0;
	width:84vw;
	// background:red;
	z-index: 80;
	transition: left 120ms ease-out;
	overflow-x: hidden;
	overflow-y: auto;
	box-shadow: 0px 10px 20px rgba(108, 121, 191, 0.1);
	// background:rgb(155, 5, 5);

	@include rwd(360){
		width:320px;
	}

	@include rwd(500){
		width:340px;
	}

	@include rwd(800){
		top: unset;
		left:unset;
		right:unset;
		
		bottom:unset;
		position: unset;
		width:100%;
		height:calc(100vh - 54px);
		box-shadow:unset;
	}

	@include xl{
		top:unset;
	}

	@include xxl{
		left:$sidebarSize4;
	}


	

	.chatMainSidebarWrapper{
		background:white;
		height:100%;
		box-sizing: border-box;
		padding:20px 0;

		.channelsList{
			margin-top:10px;
		}
	}
}

.chatMainSidebarOut{
	left:-84vw;

	@include rwd(360){left:-320px;}
	@include rwd(500){left:-340px;}
}
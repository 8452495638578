@import '../../../../../assets/scss/styles.scss';

.leaderboardUserFeedback{
	// height:200px;
	// background:blue;
	width:100%;
	box-sizing: border-box;
	padding:14px 26px;
	color:$grey1;

	.comment{
		// background:red;
		
		>h1{

		}

		>p{
			margin-top: 12px;
			color:$grey2;	
		}
	}

	>.ratings{
		margin-top: 20px;

		>.feedbackRating{
			display:flex;
			align-items: center;
			margin-top: 8px;
			justify-content: space-between;
			width:280px;
			&:first-child{
				margin-top: unset;
			}
			>h1{

			}

			>.rate{
				margin-left: 38px;
				display: flex;
				align-items: center;


				>.star{
					margin-left: 4px;
					&:first-child{
						margin-left: unset;
					}
					>svg{
						stroke:#b0ddf8;
						stroke-width:2;
						width: 12px;
					}
				}

				>.starBlue{
					>svg{
						stroke:$secondary;

					}
				}
			}
		}

	}
}
@import '../../../../assets/scss/styles.scss';

.wodScore {
	display: flex;
	align-items: center;
	color: $grey1;

	> .wodScoreWrapped {
		background: $grey4;
		border-radius: 10px;
		box-sizing: border-box;
		padding: 20px;
		margin-top: 15px;
		max-width: 450px;
		flex: 1;

		> .scoreList {
			> .scoreData {
				max-width: 450px;
				flex: 1;
				display: flex;
				align-items: center;
				justify-content: space-between;
				margin-top: 10px;
				> .left {
					> .score {
						background: $primary;
						border-radius: 7px;
						color: white;
						padding: 7px 10px;
					}
				}

				> .right {
					> .name {
					}
				}
			}
		}
	}

	> .actions {
		margin-left: 18px;
		> button {
			> svg {
				width: 18px;
				stroke-width: 2;
			}
		}
	}
}

@import '../../../assets/scss/styles.scss';

.chatInfosSidebar {
	position: fixed;
	top: 46px;
	right: 0px;
	bottom: 0;
	width: 84vw;
	background: white;
	z-index: 80;
	overflow-x: hidden;
	overflow-y: auto;
	box-shadow: 0px 10px 20px rgba(108, 121, 191, 0.1);
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	transition: right 120ms ease-out;

	@include rwd(360) {
		width: 320px;
	}
	@include rwd(500) {
		width: 340px;
	}

	@include rwd(1500) {
		position: unset;
		display: block;
		height: calc(100vh - 54px);
		width: 100%;
	}

	> .top {
		display: flex;
		flex-direction: column;
		align-items: center;
		// background:red;
		height: 100%;

		> .infos {
			display: flex;
			flex-direction: column;
			align-items: center;

			> .progIcon,
			> .userIcon {
				width: 110px !important;
				height: 110px !important;
			}

			> .progIcon {
				overflow: hidden;
				border-radius: 10px;

				> img {
					width: 100%;
					height: 100%;
					object-fit: cover;
				}
			}

			> h1 {
				color: $grey1;
				margin-top: 18px;
				padding: 0 16px;
				box-sizing: border-box;
			}

			> h2 {
				color: $grey2;
				margin-top: 5px;
			}
		}

		> .actions {
			margin-top: 46px;
		}

		> .actionsProgram {
			margin-bottom: 30px;
			display: flex;
			margin-top: 3vh;
			position: relative;
			:nth-child(2) {
				margin-left: 10px;
			}

			.dropdownMenuWrapper {
				@include qhd {
					width: 180px;
					padding: 5px;
				}
				> button {
					display: flex;
					align-items: center;
					color: $error;

					&:hover {
						color: $errorHover;
						> svg {
							stroke: $errorHover;
						}
					}

					> svg {
						margin-right: 12px;
						width: 12px;
						stroke-width: 2 !important;
						stroke: $error;
						fill: none;
						transition: all 80ms ease-in-out;
					}
				}
			}
		}

		> .memberListSeparator {
			width: 60%;
			height: 2px;
			background: $grey4;
			margin-top: 30px;
			margin-bottom: 30px;
		}

		> .memberList {
			height: 30px;
			width: 100%;
			padding: 0 18px;
			box-sizing: border-box;
			overflow-y: auto;
			flex: 1;

			@include rwd(1800) {
				padding: 0 32px;
			}

			> .coach {
				padding-bottom: 17px;
			}

			.chatMemberGroup {
				display: flex;
				align-items: center;
				justify-content: space-between;
				margin-top: 18px;
				> .actions {
					width: 12px;
					margin-left: 10px;
					cursor: pointer;
					position: relative;
					svg {
						fill: $grey2;
						width: 12px;

						&:hover {
							fill: $grey1;
						}
					}

					.dropdownActions {
						width: 160px;
						@include qhd {
							width: 180px;
							padding: 5px;
						}
						> button {
							display: flex;
							align-items: center;
							margin-top: 10px;
							color: $grey1;
							transition: all 80ms ease-in-out;

							&:first-child {
								margin-top: unset;
							}

							&:nth-child(2) {
								color: $error;

								> svg {
									stroke: $error;
								}

								&:hover {
									color: $errorHover;
									> svg {
										stroke: $errorHover;
									}
								}
							}

							&:hover {
								color: $grey1Hover;
								> svg {
									stroke: $grey1Hover;
								}
							}

							> svg {
								margin-right: 12px;
								width: 12px;
								stroke-width: 2 !important;
								stroke: $grey1;
								fill: none;
								transition: all 80ms ease-in-out;
							}
						}
					}
				}
			}
		}
	}

	> .bottom {
		margin-bottom: 10vh;
		> .actions {
			display: flex;
			flex-direction: column;
			align-items: center;
			padding: 0 15px;
			box-sizing: border-box;

			> .chatMenuItem {
				display: flex;
				align-items: center;
				margin-top: 14px;
				text-align: left;
				color: $grey1;
				transition: all 80ms ease-in-out;

				&:first-child {
					margin-top: unset;
				}

				&:hover {
					color: $grey1Hover;
					svg {
						stroke: $grey1Hover;
					}
				}

				svg {
					width: 12px;
					stroke: $grey1;
					stroke-width: 2.5;
					margin-right: 10px;
					transition: all 80ms ease-in-out;
				}
			}

			> .redItem {
				color: $error;

				&:hover {
					color: $errorHover;
					svg {
						stroke: $errorHover;
					}
				}

				svg {
					stroke: $error;
				}
			}
		}
	}
}

.chatInfoSidebarOut {
	right: -84vw;

	@include rwd(360) {
		right: -320px;
	}

	@include rwd(500) {
		right: -340px;
	}
}

@import '../../../../../assets/scss/styles.scss';

.chronoConfigModalInputSet {
	padding: 28px 38px;
	width: 82vw;
	max-width: 360px;
	box-sizing: border-box;
	color: $grey1;

	> .selector {
		margin-top: 40px;
		// background:red;
		> .timeForm {
			display: flex;
			align-items: center;
			justify-content: center;
			> .separator {
				// flex:0.5;
				text-align: center;
				font-size: 30px;
				margin-left: 20px;
				margin-right: 20px;

				@include rwd(340) {
					font-size: 40px;
				}
			}
		}

		> .roundForm {
			display: flex;
			align-items: center;
			justify-content: center;
		}

		.inputSet {
			flex: 1;
			max-width: 90px;
			display: flex;
			flex-direction: column;
			align-items: center;
			gap: 10px;
			position: relative;

			> input {
				border: 1px solid #e6eaf4;
				box-sizing: border-box;
				border-radius: 2px;
				width: 100%;
				text-align: center;
				font-size: 30px;
				color: $grey1;
				padding: 20px 5px;
				overflow: hidden;
				line-height: 20px;

				@include rwd(340) {
					font-size: 40px;
				}
			}

			> .add {
				cursor: pointer;
				> svg {
					width: 20px;
					stroke: $grey1;
					stroke-width: 3;
				}
			}

			>.units{
				position: absolute;
				bottom: 35px;
				color:$grey2;
			}
		}
	}

	@include rwd(340) {
		font-size: 40px;
	}


	> .add {
		cursor: pointer;
		> svg {
			width: 20px;
			stroke: $grey1;
			stroke-width: 3;
		}
	}
	
	> .actions {
		margin-top: 40px;
		width: 100%;
		display: flex;
		justify-content: flex-end;
	
		&:hover {
			color: $grey2;
		}
	}

	

}


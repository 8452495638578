@import '../../../../assets/scss/styles.scss';

.wodbookList {
	> .wodCat {
		display: flex;
		margin-top: 26px;

		> button {
			padding-bottom: 10px;
			width: 50%;
			text-align: center;
			box-sizing: border-box;

			&:first-child {
				margin-left: unset;
			}
		}
	}

	> .wodPublicCat {
		flex: 1;
		display: flex;
		overflow: auto;
		margin-top: 20px;
		padding-bottom: 10px;

		> button {
			border-radius: 20px;
			white-space: nowrap;
			margin-left: 14px;

			&:first-child {
				margin-left: unset;
			}
		}
	}

	> .wodLineListWrapper {
		margin-top: 4px;
	}
}

@import '../../../../../assets/scss/styles.scss';

.calendarSessionComments{
	padding:21px 28px;

	@include rwd(800){
		padding:4px 28px 21px 0px;
		box-sizing: border-box;
	}

	>.comment{
		margin-top: 24px;
		&:first-child{
			margin-top: 0;
		}
	}

	>.emptyMessage{
		margin-top: 20px;

	}

    > .moreAnswers {
      color: $grey1;
      padding-left: 15px;
      margin-top: 20px;
    }
  }

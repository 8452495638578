@import '../../../../assets/scss/styles.scss';

.calendarMobileSessionsList{

	>.sessionsProgram{
		margin-top:30px;

		&:first-child{
			margin-top: unset;
		}


		>.sessionsList{
			margin-top: 20px;

			>.emptyMessage{
				padding:12px 30px;
			}

		}
	}

}
$primary: #05DBC3;
$primaryHover: #00c0aa;
$primaryLight: #e6fcf9;
$secondary: #50B4EE;
$secondaryLight: #dcf0fc;
$secondaryHover: #279bdf;

$grey1: #4C5771;
$grey1Hover:#909fc4;
$grey2: #ABB5CD;
$grey2Hover: #929aad;
$grey3:#E6EAF4;
$grey4:#F2F5FB;

$success: #07DF9E;
$successHover: #04c289;
$warning: #FFBA07;
$warningHover: #ffca46;
$error: #FF5925;
$errorLight: #ffefea;
$errorHover:#d43201;


$darkBackground : #243F65;


$sidebarSize1 : 197px;
$sidebarSize2 : 220px;
$sidebarSize3 : 260px;
$sidebarSize4 : 300px;



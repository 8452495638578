@import '../../../../../assets/scss/styles.scss';

.calendarSessionExercice {
	background: $grey4;
	border-radius: 10px;
	box-sizing: border-box;
	overflow: hidden;

	> .row {
		display: flex;
		align-items: flex-start;
		> .blocs {
			flex: 1;
			padding: 22px 10px 22px 24px;

			> .text {
				color: $grey1;
				word-break: break-word;
				white-space: pre-wrap;
				-moz-white-space: pre-wrap;
				line-height: 16px;
			}

			> .contentBloc {
				margin-top: 15px;

				@include rwd(450) {
					margin-top: 20px;
				}

				&:first-child {
					margin-top: unset;
				}
			}
		}

		> .menu {
			padding-top: 15px;
			padding-bottom: 15px;
			> .menuMember {
				width: 50px;
				display: flex;
				flex-direction: column;
				align-items: center;
				padding-right: 5px;

				> .btnEdit {
					> svg {
						width: 12px;
						stroke: $warning;
						stroke-width: 2;
					}

					&:hover {
						> svg {
							stroke: $warningHover;
						}
					}
				}

				> .btnDelete {
					background: white;
					padding: 8px;
					margin-top: 10px;
					border-radius: 10px;

					> svg {
						width: 14px;
						stroke-width: 3;
						stroke: $error;
					}

					&:hover {
						> svg {
							color: $errorHover;
						}
					}
				}

				> .btnChrono {
					padding: 10px 9px 10px 11px;
					margin-top: 10px;
					border-radius: 12000px;
					> svg {
						width: 11px;
						stroke-width: 3;
					}
				}

				> .btnExercice {
					background: white;
					padding: 8px;
					margin-top: 10px;
					border-radius: 10px;
					position: relative;
					> svg {
						width: 15px;
						stroke-width: 2;
					}
					&:first-child {
						margin-top: unset;
					}
					&:hover {
						background: #dee1e7;
					}

					> .check {
						position: absolute;
						background: $secondary;
						padding: 1px;
						border-radius: 100px;
						left: 15px;
						bottom: 4px;

						> svg {
							width: 8px;
							stroke: white;
							stroke-width: 3;
						}
					}
				}
			}

		}
	}
}

@import '../../../../assets/scss/styles.scss';

.programsPending {
	.programsList {
		margin-top: 22px;
		@include qhd {
			margin-top: 32px;
		}

		>.progCard{
			margin-top: 10px;
			background:$grey4;
			padding:7px;
			border-radius:10px;

			&:first-child {
				margin-top: unset;
			}

			@include qhd {
				margin-top: 14px;
			}

			>.right{
				>.right{
					display: flex;
					align-items: center;
				}
			}

			>.left {
				>.infos {
					>.infosDetails{
						margin-top: 8px;
						svg{
							width: 12px;
						}
						h2 {
							font-size:12px !important;
							@include qhd{font-size: 14px !important;}
						}
					}
					
				}
			}
			
		}

		.right {
			.pseudo {
				color: $grey2;
				line-height: 18px;
			}
		}
	}

	.noData {
		margin-top: 20px;
		@include qhd {
			margin-top: 28px;
		}
	}

	.programsApplied {
		.noApplied {
			background: $primary;
			color: white;
			box-sizing: border-box;
			padding: 16px;
			border-radius: 10px;
			line-height: 20px;
			display: flex;
			align-items: center;
			justify-content: space-between;

			.btn {
				margin-left: 14px;
			}
		}
	}
}

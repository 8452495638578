@import '../../../../assets/scss/styles.scss';

.sessionLineCalendar{

	box-sizing: border-box;
	// padding: 5px;
	// border-radius: 4px;
	margin-top: 5px;
	// display: flex;
	// align-items: center;
	cursor: pointer;
	
	transition: transform 90ms ease-in-out;
	z-index: 30;

	&:hover{
		transform: scale(1.05);
		z-index:32;
	}

	@include rwd(800){
		&:hover{
			transform: scale(1.15);
			z-index:32;
		}
	}

	@include xl{
		&:hover{
			transform: scale(1.2);
			z-index:32;
		}
	}





	// >.iconPro{
	// 	width:14px;
	// 	height:14px;
	// 	border-radius: 4px;


	// 	>img{
	// 		width: 100%;
	// 		height:100%;
	// 		object-fit: cover;
	// 	}


	// }

	// >h1{
	// 	font-size: 10px !important;
	// 	font-weight: 500 !important;
	// 	margin-left: 8px;
	// }

}

.sessionLineCalendarMonth{
	display: flex;
	align-items: center;
	padding: 5px;
	border-radius: 4px;

	>.iconPro{
		width:14px;
		height:14px;
		border-radius: 4px;

		>img{
			width: 100%;
			height:100%;
			object-fit: cover;
		}
	}

	>h1{
		font-size: 10px !important;
		font-weight: 500 !important;
		margin-left: 8px;
	}
}


	.sessionLineCalendarGreen{
		background:#e6fcf9;
		color: #21B7A6;
	}

	.sessionLineCalendarBlue{
		background:#eef8fe;
		color: #3391C8;
	}

	.sessionLineCalendarGreenOld{
		background: repeating-linear-gradient(
			120deg,
			#f9fdfe,
			#f9fdfe 10px,
			#edfcfb 10px,
			#edfcfb 20px
		);
	}

	.sessionLineCalendarBlueOld{
		background: repeating-linear-gradient(
			120deg,
			#fcfeff,
			#fcfeff 10px,
			#f2f9fe 10px,
			#f2f9fe 20px
		);
	}




.sessionLineCalendarWeek{
	border-radius: 4px;
	overflow: hidden;

	>.titleBar{

		padding:9px 14px;

		font-size: 12px !important;
		line-height: 13px;
		font-weight: 500 !important;

		@include rwd(800){
			font-size: 11px !important;
			padding:10px 8px;
		}
		// margin-left: 8px;
	}

	>.programInfos{
		// background:red;
		padding:10px 14px;

		@include rwd(800){
			padding:10px;
		}
		>.infos{
			display: flex;
			flex-direction: row;
			align-items: flex-start;
			text-align: unset;

			@include rwd(800){

				flex-direction: column;
				align-items: center;
				text-align: center;
			}

			@include lg{
				flex-direction: row;
				align-items: flex-start;
				text-align: unset;
				
			}

			>.image{
				width:26px;
				height:26px;
				border-radius: 4px;
				box-shadow: 0 0 10px rgba(0,0,0,0.2);
				overflow: hidden;
				margin-top: 2px;
				>img{
					width:100%;
					height:100%;
					object-fit: cover;
				}

				@include rwd(800){
					width:18px;
					height:18px;
				}
			}

			>.texts{
				flex:1;
				// margin-left: 8px;
				color:$grey1;
				// overflow: hidden;
				margin-top: 0px;
				margin-left: 15px;

				@include rwd(800){
					margin-top: 7px;
					margin-left: 0px;
				}

				@include lg{
					margin-top: unset;
					margin-left: 8px;
				}

				>h1{
					font-size: 12px;
					line-height: 13px;
					@include rwd(800){
						font-size: 10px;
					}
				}

				>h2{
					font-size: 11px;
					margin-top: 5px;
					text-decoration: underline;
					@include rwd(800){
						font-size: 10px;
						margin-top: 3px;
					}
				}
			}


		}

		>.exercicesNumber{
			font-size: 11px !important;
			// color:#21B7A6;
			margin-top: 10px;
			text-align: unset;
			@include rwd(800){
				text-align: center;
				font-size: 11px !important;
				margin-top: 10px;
			}
			@include lg{
				text-align: unset;
			}
		}
	}
}


	.sessionLineWeekGreenTitle{
		background:#d6f9f5;
		color: #21B7A6;
	}

	.sessionLineWeekBlueTitle{
		background:#e3f3fd;
		color: #3391C8;
	}

	.sessionLineWeekGreenBody{
		background: #edfcfb;
	}

	.sessionLineWeekBlueBody{
		background: #f3fafe;
	}










.sessionLineCalendar.dragging{
	// background:red !important;
	opacity:0.5;
}
@import '../../../../assets/scss/styles.scss';

.informationsWidgetWrapper{
	display: flex;
	flex-direction: column;

	hr{
		border: none;
		border-top: 1px solid $grey4;
		background: unset;
		box-sizing: border-box;
		width: calc(100% - 8px);
		margin-top:20px;
		margin-bottom:20px;
	}

	.informationseWidgetInfos{
		display: flex;
		flex-direction: column;

		> div {
			margin-top:30px;

			&:first-child{
				margin-top:10px;
			}

			@include qhd{
				margin-top:34px;
			}
		}
	}
}
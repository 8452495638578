@import '../../assets/scss/styles.scss';

.widgetProfile{
	box-sizing: border-box;
	width:100%;
	padding:30px 27px;
	border-radius: 20px;
	background:white;
	overflow: hidden;
	color:$grey1;
	svg{
		stroke-linecap: round;
		stroke-linejoin: round;
		fill:none;
	}

	// .textNormal{color:$grey1;}
}


.widgetProfileSmall{
	max-width: 325px !important;
	padding-top:20px !important;
	padding-bottom:20px !important;
}

.widgetPaddingOff{
	padding:unset;
}
@import '../../../../assets/scss/styles';

.searchMember {
    > h1 {
      color: $grey1;
      margin-bottom: 20px;
    }

	width: 100%;
	position: relative;
	z-index: 20;

	input[type='text'] {
		width: 100%;
		color: $grey1;
		box-sizing: border-box;
		padding: 13px 12px;
		border: 1px solid $grey3;
		border-radius: 2px;

		&::placeholder {
			color: $grey2;
			opacity: 1;
		}
	}

	.searchMemberModal {
		width: 100%;
		height: 300px;
		position: absolute;
		top: 80px;
		padding: 30px 26px;
		box-sizing: border-box;
		z-index: 10001;
		border-radius: 5px;
		background: #ffffff;
		box-shadow: 0px 10px 20px rgba(108, 121, 191, 0.1);
		transition: all 300ms ease-in-out;
		overflow-y: auto;

		.resultLine {
			display: flex;
			justify-content: space-between;
			align-items: center;
			margin-top: 20px;

			&:first-child {
				margin-top: unset;
			}
			.left {
				max-width: 200px;
			}

			.right {
			}
		}
	}
}

@import '../../../../../assets/scss/styles.scss';

.timerFormStep{
	border:2px dashed $grey3;
	border-radius: 10px;
	box-sizing: border-box;
	padding:18px 20px;
	position:relative;
	// border-style: dashed;

	>.closeButton{
		border:2px solid $grey3;
		border-radius: 1000px;
		padding:4px;
		position: absolute;
		top:-13px;
		right:-13px;
		background:$darkBackground;
		> svg{
			width:18px;
			stroke:$grey3;
			stroke-width:3;
		}
	}
}
@import './styles.scss';

// Text at 9px on Figma
.textSmall{
	font-size:11px;

	@include qhd{font-size: 13px;}
}

// Text at 10px on Figma
.textNormal{
	font-size:12px;

	@include qhd{font-size: 14px;}
}

// Text at 11px on Figma
.textMedium{
	font-size:13px;

	@include qhd{font-size: 15px;}
}

// Title at 12px on Figma
.titleL{
	font-size: 14px;

	@include qhd{font-size: 16px;}
}

// Title at 13px on Figma
.titleXL{
	font-size: 16px;

	@include qhd{font-size: 18px;}
}

// Title at 15px on Figma
.titleXXL{
	font-size: 18px;

	@include qhd{font-size: 20px;}
}

// Title at 20px on Figma
.titleXXXL{
	font-size: 22px;

	@include qhd{font-size: 24px;}
}

.weightNormal{font-weight: 400;}
.weightMedium{font-weight: 500;}
.weightSemiBold{font-weight: 600;}
.weightBold{font-weight: 700;}

.textGrey1{
  color: $grey1;
}

.textGrey2{
  color: $grey2;
}

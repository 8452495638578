@import '../../assets/scss/styles.scss';

.buttonProfileWidget{
	display:flex;
	// grid-template-columns: auto auto;
	// column-gap: 9px;
	justify-content: center;
	align-items: center;
	box-sizing: border-box;
	width:100%;
	padding:14px 0;

	background:white;
	border:2px solid white;
	border-radius: 10px;
	
	transition: border 120ms ease-in-out;
	
	&:hover{border:2px solid $secondary;}

	svg{
		width:12px;
		height:12px;
		stroke: $secondary;
		stroke-width:3;
		fill:none;
		margin-right:10px;

		@include qhd{
			width:14px;
			height:14px;
		}
	}

	h1{color: $secondary;}
}

.editProfilButtonWidgetLargeIcon{
	svg{
		width:14px;
		height:14px;
	}
}
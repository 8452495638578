@import '../../../../assets/scss/styles.scss';

.chatArea{
	width:100%;
	// height:3000px;
	// background: red;
	// overflow:hidden;
	min-height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	padding:0px 30px;
	box-sizing: border-box;
	z-index:20;

	@include xl{
		padding:0px 45px;
	}

	>.listeMessages{
		display: flex;
		flex-direction: column-reverse;
		// background:green;
		// overflow: auto;
		margin-bottom:40px;
		margin-top:30px;

		>.listeMessageDatebloc{
			>.dateShow{
				width:100%;
				text-align: center;
				color:$grey2;
				padding:20px 20px 6px 20px;
				box-sizing: border-box;


			}

			>.messagesContainer{
				display: flex;
				flex-direction: column-reverse;
				// background:purple;

				.messageGroup{
					// background:red;
					display: flex;
					align-items: flex-end;
					margin-top:10px;
					max-width: 100%;
					// overflow: hidden;
					

					>.avatar{
						// width:20px;
						margin-right:10px;
						margin-bottom:4px;
					}

					>.messagesList{
						display: flex;
						flex-direction: column-reverse;
						flex:1;
						
						>.messageBloc{
							max-width: 100%;
							margin-top:4px;
							
						}
					}
					

				}
			}
		}
	}

	>.firstMessage{
		text-align: center;
		color:$grey2;
		margin-bottom: 40px;
	}
}
@import '../../assets/scss/styles.scss';

.widgetTitle{
	display: flex;
	align-items: center;
	justify-content: space-between;

	.title{color:$grey1;}
}


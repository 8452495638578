@import '../../../assets/scss/styles.scss';

#programCreate {
	box-sizing: border-box;
	padding: 28px 26px;
	position: relative;
	max-width: 550px;
	margin-left: auto;
	margin-right: auto;

	@include rwd(860) {
		padding-top: 40px;
		padding-bottom: 60px;
	}

	@include xl {
		margin-left: unset;
		margin-right: unset;
	}

	.pageTitle {
		margin-top: 18px;
		color: $grey1;
	}

	> .createSteps {
		padding-top: 10px;

		@include qhd {
			padding-top: 20px;
		}

		> .programAddStep {
			margin-top: 8px;
			@include qhd {
				margin-top: 14px;
			}
			> .title {
				display: flex;
				align-items: center;
				cursor: pointer;
				.stepNumber {
					border-radius: 5px;
					background: $secondary;
					width: 24px;
					height: 24px;
					box-sizing: border-box;
					display: flex;
					align-items: center;
					justify-content: center;
					overflow: hidden;
					color: white;
				}
				> h1 {
					color: $grey1;
					margin-left: 18px;
				}
				> .help {
					svg {
						width: 16px;
						height:16px;
						stroke: $grey2;
						stroke-width: 2;
						margin-left: 12px;
						padding-top: 2px;
					}
				}

				.helpModal{
					padding: 28px 38px;
					max-width: 500px;
					line-height: 24px;

					@include qhd{
						line-height: 27px;
					}
				}
			}

			> .subtitle{
				margin-left:42px;
				margin-top:5px;
				display: flex;
				cursor:pointer;

				svg{
					stroke:$grey1;
					stroke-width: 3;
					width:12px;
					height:12px;
					margin-left:8px;
				}
			}

			> .contentStep {
				display: flex;
				margin-top: 6px;
				overflow: hidden;
				max-height: 1000px;
				transition: max-height 0.7s ease-in-out;
				max-width: 480px;

				> .decoration {
					width: 24px;
					display: flex;
					justify-content: center;
					> .line {
						width: 1px;
						height: 100%;
						background: $grey3;
					}
				}

				> .contentStepWrapper {
					margin-left: 20px;
					flex: 1;
					padding: 20px 0;
				}
			}

			.contentStep[aria-expanded='false'] {
				max-height: 0px;
				// background:purple !important;
				transition: max-height 0.2s cubic-bezier(0, 1, 0, 1);
			}
		}

		.programStep {
			.choiceButton {
				margin-top: 10px;
				&:first-child {
					margin-top: unset;
				}
			}
		}

		.programStepType {
          .modalSession{
            padding:60px 20px;
            min-width: none;
            max-width: 300px;
            text-align: center;
            >h1{
              color:$grey1;
              margin-bottom: 35px;
              font-size: 24px;
            }
            >ul{
              >li{
                line-height: 20px;
                font-size: 16px;
              }
            }

            >p{
              line-height: 20px;
              font-size: 10px;
            }
            .bodyText1{
              font-size: 14px;
              line-height: 20px;
              margin-bottom: 20px;
              color:$grey1;
              text-align: left;
            }

            >.actions{
              display: flex;
              justify-content: space-between;
              margin-top: 35px;
              >.btn{
                margin-left: auto;
                margin-right: auto;
                line-height: 25px;
                font-size: 14px;
                min-width: 200px;
                //   padding:0;
              }
            }
          }
		}

		.programStepConfident {
		}

		.programStepInfos {
			margin-top: 7px;
		}

		.choiceButton {
			background: $grey4;
			border-radius: 10px;
			width: 98%;
			padding: 20px;
			box-sizing: border-box;
			cursor: pointer;
			transition: transform 120ms ease-in;

			&:hover {
				transform: scale(1.03);
			}

			> .title {
				display: flex;
				align-items: center;
				.iconWrapper {
					width: 26px;
					height: 26px;
					display: flex;
					align-items: center;
					justify-content: center;
					border-radius: 7px;
					svg {
						stroke: white;
						stroke-width: 2;
						width: 50%;
					}
				}

				h1 {
					color: $grey1;
					margin-left: 10px;
				}
			}

			> p {
				margin-top: 10px;
				color: $grey2;
				line-height: 18px;
			}
		}

		.iconGreen {
			background: $primary;
		}
		.iconBlue {
			background: $secondary;
		}
		.iconGrey {
			background: $grey2;
		}
	}
}

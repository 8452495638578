@import '../../../../../../assets/scss/styles.scss';

.modalTimer{
	position:fixed;
	top:0;
	left:0;
	bottom:0;
	right:0;
	background:rgba(0,0,0,0.5);
	z-index: 10000000000000;

	display: flex;
	flex-direction: column;
	// align-items: center;
	justify-content: flex-end;
	transition: opacity 200ms ease-out;

	.modalTimerWindow{
		width:100%;
		height:80vh;
		overflow:hidden;
		background:white;
		box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.25);
		// border-radius: 10px;
		transition: all 170ms ease-out;
		transform-style: preserve-3d;
		
		

		.modalTimerWindowWrapper{
			width:100%;
			max-height: 80vh;
			overflow-y: auto;
			
		}
	}
}


.modalTimerOut{
	opacity:0;
}

.modalTimerWindowOut{
	// transform: rotateX(-20deg) scale(0.9);
	transform: translateY(80vh);
}
@import '../../../assets/scss/styles.scss';

.chatMobileNavbar{
	display: flex;
	align-items: center;
	justify-content: space-between;
	box-sizing: border-box;
	height:52px;
	padding:0 21px;
	position: relative;
	background:white;

	@include rwd(800){
		justify-content: flex-end;
		// position:fixed;
		// left: 320px;
		// right:0;
	}

	@include xl{
		// left: 580px;
		// background:green;
	}

	@include rwd(1400){
		// left: 620px;
	}

	@include rwd(1500){

	}


	>.left{
		width:16px;
		cursor:pointer;
		@include rwd(800){
			display:none;
		}
		>svg{
			stroke:$grey1;
			stroke-width: 2;
			width:16px;
		}
	}

	>.center{
		color:$grey1;
		position: absolute;
		left:50%;
		transform: translateX(-50%);
		display: flex;
		align-items: center;

		>.progIcon, >.userIcon{
			width:28px !important;
			height:28px !important; 
			// margin-right:20px;
		}

		>.progIcon{
			overflow:hidden;
			border-radius: 10px;
			img{
				width:100%;
				height:100%;
				object-fit: cover;
			}
		}

		>.name{
			margin-left:14px;

		}


	}
	>.right{
		position:relative;
		z-index:30;
		>.iconContainer >svg{
			// stroke:$grey1;
			// stroke-width: 2;
			height:14px;
			fill:$grey2;
			padding:0 12px;
			cursor:pointer;
		}

		>.dropdownMenu{
			top:24px;
			right:12px;
			width:190px;
			z-index: 40;
			.chatMobileMenuItem{
				display: flex;
				align-items: center;
				margin-top:14px;
				text-align: left;
				color:$grey1;
				cursor:pointer;
				
				transition:all 80ms ease-in-out;
				
				&:first-child{
					margin-top:unset;
				}

				&:hover{
					color:$grey1Hover;
					svg{
						stroke:$grey1Hover;
					}
				}

				svg{
					width:13px;
					stroke:$grey1;
					stroke-width:2.5;
					margin-right:10px;
					transition:all 80ms ease-in-out;
					// background:red;
				}
			}

			.redItem{
				color:$error;
				
				&:hover{
					color:$errorHover;
					svg{
						stroke:$errorHover;
					}
				}

				svg{
					stroke:$error
				}
			}
		}
	}
}